import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Grid } from "react-flexbox-grid";
import { getCountProcessByUser } from "../../table_process/table_process_logic";
import Cookies from "js-cookie";
import { Badge } from "react-bootstrap";
import DomainContext from "../../domains/domainPathContext";
import CountProcessContext from "./count_process_context";

const TopMenu = props => {
  const { special_permissions_03 } = Cookies.get();
  const [specialPermissionsStart, setSpecialPermissionsStart] = useState(false);
  const subdomain = useContext(DomainContext);
  const [specialPermissionsPause, setSpecialPermissionsPause] = useState(false);
  const CountProcess = useContext(CountProcessContext);

  const startProcedureFlag = async () => {
    if (special_permissions_03) {
      if (JSON.parse(special_permissions_03)) {
        JSON.parse(special_permissions_03).find(obj => {
          if (obj.start) {
            if (obj.start === "1") {
              setSpecialPermissionsStart(true);
            }
          }
          if (obj.pause) {
            if ( obj.pause === "1" ) setSpecialPermissionsPause(true);
          }
          return true;
        });
      }
    }
  };

  useEffect(() => {
    (async function() {
      await getCountProcessByUser( CountProcess );
      startProcedureFlag();
    })();
  }, []);

  return (
    <Grid fluid={true} className="principal-nav">
      <Grid>
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <Link
              to={`/${subdomain}/home`}
              className={
                "nav-link " +
                (props.pageConfig.active === "home" ? "active" : "")
              }
            >
              Inicio
            </Link>
          </li>

          {specialPermissionsStart ? (
            <li className="nav-item">
              <Link
                to={`/${subdomain}/process_list`}
                className={
                  "nav-link " +
                  (props.pageConfig.active === "available_process"
                    ? "active"
                    : "")
                }
              >
                Trámites Disponibles
              </Link>
            </li>
          ) : (
            ""
          )}
          {specialPermissionsStart ? (
            <li className="nav-item">
              <Link
                to={`/${subdomain}/table_process/my_procedures`}
                className={
                  "nav-link " +
                  (props.pageConfig.active === "my_procedures" ? "active" : "")
                }
              >
                Trámites iniciados{" "}
                <Badge variant="complement-4">
                  { CountProcess.countProcess && !isNaN(CountProcess.countProcess.my_procedures) ? CountProcess.countProcess.my_procedures : 0 }
                </Badge>
              </Link>
            </li>
          ) : (
            ""
          )}

          <li className="nav-item">
            <Link
              to={`/${subdomain}/table_process/pending`}
              className={
                "nav-link " +
                (props.pageConfig.active === "formalities_to_attend"
                  ? "active"
                  : "")
              }
            >
              Por Atender{" "}
              <Badge variant="complement-4">{ CountProcess.countProcess && !isNaN(CountProcess.countProcess.to_attend) ? CountProcess.countProcess.to_attend : 0 }</Badge>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to={`/${subdomain}/table_process/assign`}
              className={
                "nav-link " +
                (props.pageConfig.active === "formalities_attend"
                  ? "active"
                  : "")
              }
            >
              Atendidos{" "}
              <Badge variant="complement-4">{ CountProcess.countProcess && !isNaN(CountProcess.countProcess.attend) ? CountProcess.countProcess.attend : 0 }</Badge>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to={`/${subdomain}/table_process/no_assign`}
              className={
                "nav-link " +
                (props.pageConfig.active === "formalities_unassigned"
                  ? "active"
                  : "")
              }
            >
              No Asignados{" "}
              <Badge variant="complement-4">{ CountProcess.countProcess && !isNaN(CountProcess.countProcess.unassigned) ? CountProcess.countProcess.unassigned : 0 }</Badge>
            </Link>
          </li>

          { specialPermissionsPause ? (
            <li className="nav-item">
              <Link
                to={`/${subdomain}/table_process/paused`}
                className={
                  "nav-link " +
                  (props.pageConfig.active === "formalities_paused"
                    ? "active"
                    : "")
                }
              >
                Pausados{" "}
                <Badge variant="complement-4">{ CountProcess.countProcess && !isNaN(CountProcess.countProcess.paused) ? CountProcess.countProcess.paused : 0 }</Badge>
              </Link>
            </li>
          ) : (
            ""
          )
          }
        </ul>
      </Grid>
    </Grid>
  );
};

export default TopMenu;
