import React, { useContext } from "react";
import { pdfjs } from "react-pdf";
import { Col } from "react-flexbox-grid";
import { xmlHttpRequestManualDownload } from "../../user_manual/user_manual_logic";
import LoaderContext from "../../layout/shared/loader_context";
import LoaderComponent from "../../layout/shared/Loader";
import DigitalSignatureModal from "../digital_signature/digitalSignatureModal";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const DynamicPDF = props => {
  const Loader = useContext(LoaderContext);
  let signatureModal;
  if (props.url) {
  } else {
    signatureModal = props.el.signature ? (
      <Col className="p-5" xs={12}>
        <DigitalSignatureModal {...props} />
      </Col>
    ) : (
      ""
    );
  }

  const downloadFile = (url) => {
    const today = new Date().toLocaleDateString('es-MX',{
      year: 'numeric', 
      month: 'short', 
      day: 'numeric'
    });
    const name = "documento_" + today.replace(/ /g,"_") + ".pdf"
    
    Loader.show(true);
    xmlHttpRequestManualDownload(url, function(blob){
      let a = document.createElement('a');
      let blobb = new Blob([blob], {type: 'application/pdf'});
      let url = URL.createObjectURL(blobb);
      a.href = url;
      a.download = name;
      a.click();  
      Loader.show(false);
    });
  };

  return (
    <Col xs={12} className={props.el.visibility["status"] ? "" : " d-none"}>
      {signatureModal}

      <button
        className="btn btn-secondary btn-lg"
        onClick={ () => downloadFile(props.url) }
      >
        Descargar Archivo
      </button>

      <LoaderComponent/>
    </Col>
  );
};

export default DynamicPDF;
