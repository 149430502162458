import { Request } from "./components/classes/Request";
import Cookies from 'js-cookie';

/**********************************************************FORGOT PASSWORD************************************************************************/
export const getLayoutData = async () => {
  let domainId = Cookies.get('domain_id_03');
  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/app_config?token=${process.env.REACT_APP_TOKEN}&domain_id=${domainId}`,
    "get",
    null,
    {}
  );
  const getLayoutDataConfig = await request.executeRequest(false);
  if (getLayoutDataConfig.code === 200) {
    //MOSTRAR ALERTA EXITOSA CON EL MENSAJE QUE RETORNA LA API COMPONENTE
    Cookies.set('layout_03', getLayoutDataConfig.data.data.data.config);
  }
};
/***********************************************************************************************************************************************/
