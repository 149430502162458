import { NotificationManager } from "react-notifications";
import { RequestNewVersion } from "../classes/RequestNewVersion";

export const initForm = {
  newPassword: "",
  confirmNewPassword: ""
};

/**********************************************************FORGOT PASSWORD************************************************************************/
export const UpdatePasswordRecovery = async (form, resetPswToken, props, subdomain) => {

  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/auth/password`,
    "put",
    null,
    {
      password: form.newPassword,
      password_confirmation: form.confirmNewPassword,
      reset_password_token: resetPswToken
    }
  );
  const updatePasswordRecoveryData = await request.executeRequest();
  
  if ( updatePasswordRecoveryData.code !== 200 ) {

    NotificationManager.error( "Ha sucedido algo y tu solicitud no pudo ser realizada intentálo nuevamente" );
  }
  else {

    if (
      updatePasswordRecoveryData.response.error && 
      updatePasswordRecoveryData.response.message && 
      updatePasswordRecoveryData.response.message !== "" && 
      typeof updatePasswordRecoveryData.response.message === "string" 
    ) {

      NotificationManager.error( updatePasswordRecoveryData.response.message );
    }
    else {

      NotificationManager.success( updatePasswordRecoveryData.response.message );
      props.history.push(`/${subdomain}/`);
    }
  }

};
/***********************************************************************************************************************************************/
