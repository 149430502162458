export const executePKCS7 = async (kPem, cPem, extraAttributesField, password) => {

	var privateKeyBuffer = new ArrayBuffer(0); 
	var certificateBuffer = new ArrayBuffer(0);

	return new Promise( (resolve, reject) => {
		
		const { signature_chain } = extraAttributesField;
		let dataToSign = signature_chain;

		privateKeyBuffer = kPem;
  		certificateBuffer = cPem;
		dataToSign = JSON.stringify(dataToSign);

		var cipheredKey;
		var privateKeyBufferString = window.arrayBufferToString(privateKeyBuffer);
		var pKey = privateKeyBufferString.replace(/(-----(BEGIN|END) PRIVATE KEY-----|\r\n)/g, '');
	  
		if (pKey.charAt(0) === "M") {
			cipheredKey = window.atob(pKey);
		} else {
			cipheredKey = privateKeyBufferString;
		}
	  
		var certKey;
		var certKeyBufferString = window.arrayBufferToString(certificateBuffer);
		var pCert = certKeyBufferString.replace(/(-----(BEGIN|END) CERTIFICATE-----|\r\n)/g, '');
	  
		if (pCert.charAt(0) === "M") {
			certKey = window.atob(pCert);
		} else {
			certKey = certKeyBufferString;
		}
		
		var pkcs7Promise = window.pkcs7FromContent(password, cipheredKey, certKey, "SHA-256", dataToSign, false);
		pkcs7Promise.then(function(Pkcs7) {
			var b64PKCS7Message = window.btoa(window.arrayBufferToString(Pkcs7, false, true));
			resolve(b64PKCS7Message)
		}, function(error) {
			reject("[SgDataCrypto][pkcs7FromContent] " + error.message);
		});

	});
};

