import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { Col, Form } from 'react-bootstrap';
import {
  saveFileServer
} from '../../dynamic_form/dynamic_form_logic';
import Cookies from 'js-cookie';

const DynamicFile = ({
  el, onObservationClickHandler, disableFieldBasedMethod,
  fileData, disableUploadButton, setDisableUploadButton, state, setState,
  props, LoaderStatus, temporaryUrls, setTemporaryUrls, setFilesWithHeaders, 
  filesWithHeaders, setPreviewFileImage, setPreviewImgUrl, setTypeViewFile
}) => {
  const { authentication_token_03 } = Cookies.get();
  const [urlHere, setUrlHere] = useState(null);

  useEffect(() => {
    (async function(){
      if (fileData['route']) await fetch(
        fileData['route'], 
        { headers:{ Authorization: authentication_token_03 } }
      )
      .then( response => response.blob().then(
        blob => {
          let hereTemporaryURLS = [...temporaryUrls];
          let temporaryURL = URL.createObjectURL(blob);
          hereTemporaryURLS.push(temporaryURL);
          setUrlHere(temporaryURL);
          setTemporaryUrls(hereTemporaryURLS);
          setFilesWithHeaders(
            filesWithHeaders => {
              if (filesWithHeaders.length > 0) {
                const index = filesWithHeaders.map( (file) => file.elementId).indexOf(el.id);
                
                if (index === -1) {
                  return [...filesWithHeaders, { elementId:el.id, url:temporaryURL }];
                }
                else {
                  let newItem = {elementId:el.id, url:temporaryURL};
                  setFilesWithHeaders(
                    filesWithHeaders.map( (file, i) => file.elementId === el.id ? newItem : file)
                  );
                }
              }
              else {
                return [...filesWithHeaders, { elementId:el.id, url:temporaryURL }];
              }
            }
          );
        }
      ))
    })();
  }, []);

  const onDrop = async (acceptedFiles, el) => {
    if (!disableUploadButton) {
      let file = acceptedFiles[0];
      const reader = new FileReader();
      setDisableUploadButton(true);

      reader.onload = async event => {
        setState({ ...state, [el.name + "_status"]: 1 });

        const fileSaved = await saveFileServer(
          event.target.result,
          props.history.location.state.params.stage,
          el,
        );

        if (fileSaved) {
          let temporaryURL = "";
          const documentManagerFile = fileSaved['key'] && fileSaved['key']['access_key'];
          if (documentManagerFile) {
            let hereTemporaryURLS = [...temporaryUrls];
            temporaryURL = URL.createObjectURL(file);
            hereTemporaryURLS.push(temporaryURL);
            setUrlHere(temporaryURL);
            setState({
              ...state,
              [el.name]: fileSaved.key,
              [el.name + "_isUploadToDocumentManager"]: true,
              [el.name + "_img"]: temporaryURL,
              [el.name + "_ext"]: fileSaved.extension,
              [el.name + "_loader"]: false,
              [el.name + "_status"]: 2
            });
            resetFilesWithHeaders(temporaryURL, el);
            setTemporaryUrls(hereTemporaryURLS);
          }
          else {
            setState({
              ...state,
              [el.name]: fileSaved.key,
              [el.name + "_img"]: fileSaved.url,
              [el.name + "_ext"]: fileSaved.extension,
              [el.name + "_loader"]: false,
              [el.name + "_status"]: 2,
            });
            setUrlHere(fileSaved.url);
          }
        } else {
          setTimeout(() => {
            setState({ [el.name + "_status"]: 0 });
          }, 700);
        }

        setDisableUploadButton(false);
      };

      reader.readAsDataURL(file);
    }
  };

  const downloadFileWithHeaders = (file, element, fileData) => {
    LoaderStatus.show(true);
    let a = document.createElement('a');
    const name = element['name'] + '.' + fileData['extension'];
    a.href = file['url'];
    a.download = name;
    a.click();
    LoaderStatus.show(false);  
  };

  const downloadDocumentFileWithHeaders = async (element, fileData) => {
    LoaderStatus.show(true);
    await fetch(fileData['route'], { headers:{ Authorization: authentication_token_03 } })
      .then( async response => await response.blob().then( blob => {
        let hereTemporaryURLS = [...temporaryUrls];
        let a = document.createElement('a');
        let temporaryURL = URL.createObjectURL(blob);
        hereTemporaryURLS.push(temporaryURL);
        const name = element['name'] + '.' + fileData['extension'];
        a.href = temporaryURL;
        a.download = name;
        a.click();
        setTemporaryUrls(hereTemporaryURLS);
      }
    ));
    LoaderStatus.show(false);
  };

  const previewFileImgHandler = (state, el, type) => {
    const index = checkIfFileExistsInState(el);
    setTypeViewFile(type);
    if (index !== -1) {
      setPreviewImgUrl(filesWithHeaders[index].url);
      setPreviewFileImage(true);
    }
    else {
      setPreviewImgUrl(state[el.name + "_img"]);
      setPreviewFileImage(true);
    }
  };

  const resetFilesWithHeaders = (temporaryURL, currentEl) => {
    setFilesWithHeaders(
      filesWithHeaders => {
        if (filesWithHeaders.length > 0) {
          const index = filesWithHeaders.map( (file) => file.elementId).indexOf(currentEl.id);

          if (index === -1) {
            return [...filesWithHeaders, { elementId:currentEl.id, url:temporaryURL }];
          }
          else {
            let newItem = {elementId:currentEl.id, url:temporaryURL};
            setFilesWithHeaders(
              filesWithHeaders.map( (file, i) => file.elementId === currentEl.id ? newItem : file)
            );
          }
        }
        else {
          return [...filesWithHeaders, { elementId:currentEl.id, url:temporaryURL }];
        }
      }
    );
  };

  const checkIfFileExistsInState = (el) => {
    const index = filesWithHeaders.map( (file) => file.elementId).indexOf(el.id);
    return index;
  };

  return (
    <Col
      className={el.visibility["status"] ? "" : " d-none"}
      xs={12}
      md={el.width}
    >
      <Col xs={12}>
        <Form.Label
          className={
            "font-weight-bold " + (el.error ? "text-danger" : "")
          }
        >
          <span
            style={
              el.requiredSignal
                ? { display: "inline" }
                : { display: "none" }
            }
          >
            <b>* </b>
          </span>
          {el.label}
          <span
            className="observation-input-icon"
            style={{
              display:
                el.is_validable && props.mode === 3
                  ? "inline-block"
                  : "none",
            }}
            onClick={() => onObservationClickHandler(el)}
          >
            <i
              className={
                "far fa-comment-dots" +
                (el.observations ? " text-danger" : "")
              }
            />
          </span>
        </Form.Label>

        <Form.Group
          style={disableFieldBasedMethod(el) ? { display: "none" } : null}
          controlId="formBasicFile"
        >
          <Dropzone onDrop={event => onDrop(event, el)}>
            {({ getRootProps, getInputProps }) => (
              <div className="file-nilo-wrapper" {...getRootProps()}>
                Seleccionar un archivo
                <input
                  style={{ display: "inline!important" }}
                  className={
                    el.error ? " form-control is-invalid" : "form-control"
                  }
                  {...getInputProps()}
                  disabled={disableFieldBasedMethod(el)}
                  name={el.name}
                  key={el.id}
                />
              </div>
            )}
          </Dropzone>
          {/* eslint-disable-next-line */}
          <a
            style={
              el.error
                ? { display: "inline", color: "red" }
                : { display: "none" }
            }
          >
            {" "}
            {el.error ? el.error[0] : ""}
          </a>
        </Form.Group>

        <div
          className="file-upload-wrapper"
          style={
            fileData.isImage &&
            (fileData.status === 2 || fileData.status === 1)
              ? { display: "inline-block" }
              : { display: "none" }
          }
        >
          <div
            className={
              "file-upload-actions " +
              (fileData.status === 2 ? "d-block" : "d-none")
            }
            style={{zIndex:1}}
          >
            <i
              className="fas fa-search-plus"
              style={{ cursor: "pointer" }}
              title="preview image"
              onClick={() => previewFileImgHandler(state, el, true)}
            />
            <i
              className="fas fa-cloud-download-alt"
              style={{ cursor: "pointer" }}
              title="dowload file"
              onClick={() => {
                let index = checkIfFileExistsInState(el);

                if (index !== -1) downloadFileWithHeaders(filesWithHeaders[index], el, fileData)
                else window.open(`${fileData.route}`, "_blank");
              }}
            />
          </div>
          <img
            className={
              "loader " + (fileData.status === 1 ? "d-block" : "d-none")
            }
            src={process.env.PUBLIC_URL + "/img/loader.gif"}
            alt="Cargando ..."
          />
          <img
            src={urlHere}
            alt="File"
            className={fileData.status === 2 ? "d-block" : "d-none"}
            style={
              state[el.name]
                ? { display: "inline-block", zIndex:1 }
                : { display: "none" }
            }
          />
        </div>

        <div
          className={
            "file-upload-wrapper " +
            (!fileData.isImage &&
            (fileData.status === 2 || fileData.status === 1)
              ? "d-block"
              : "d-none")
          }
        >
          <div
            className={
              "file-upload-actions " +
              (fileData.status === 2 ? "d-block" : "d-none")
            }
            style={{zIndex:1}}
          >
            <i
              className="fas fa-search-plus"
              style={{ cursor: "pointer" }}
              title="preview pdf"
              onClick={() => previewFileImgHandler(state, el, false)}
            />
            <i
              className="fas fa-cloud-download-alt"
              style={{ cursor: "pointer" }}
              title="dowload file"
              onClick={() => {
                window.open(`${fileData.route}`, "_blank");
              }}
            />
          </div>

          <img
            className={
              "loader w-100 " +
              (fileData.status === 1 ? "d-block" : "d-none")
            }
            src={process.env.PUBLIC_URL + "/img/loader.gif"}
            alt="Cargando ..."
          />

          <div
            className={fileData.status === 2 ? "d-block cursor-pointer document-view" : "d-none"}
            onClick={ () => downloadDocumentFileWithHeaders(el, fileData) }
            title={"Da clic para descargar"}
          >
            <img
              className={fileData.status === 2 ? "d-block" : "d-none"}
              src={fileData.icon}
              alt="Archivo descargable"
            />
          </div>
        </div>
      </Col>
      <Col xs={12} sm={12}>
        <small
          id={`small${el.name}`}
          className="form-text text-muted mt-1"
        >
          {el.contextual_help ? el.contextual_help : ""}
        </small>
      </Col>
    </Col>
  );
};

export default DynamicFile;
