import Cookies from "js-cookie";
import { DestroyCookies } from "../login/login_logic";
import { RequestNewVersion } from "../classes/RequestNewVersion";
import { NotificationManager } from "react-notifications";

export const initForm = {
  oldPassword: "",
  newPassword: "",
  passwordConfirm: ""
};

/**********************************************************FORGOT PASSWORD************************************************************************/
export const changePassword = async (form, props, subdomain) => {
  const { userId_03 } = Cookies.get();
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/users/change/password/${userId_03}`,
    "patch",
    null,
    {
      new_password: form.newPassword,
      password_confirmation: form.passwordConfirm,
      current_password: form.oldPassword,
    }
  );
  const validateChangePassword = await request.executeRequest();

  if (
    validateChangePassword.code === 200 && 
    !validateChangePassword.response.error && 
    typeof validateChangePassword.response.message === "string" && 
    validateChangePassword.response.message !== "" ) {

      NotificationManager.success(validateChangePassword.response.message);

      setTimeout(() => {
        DestroyCookies();
        window.location.href = `/${subdomain}`;
      }, 700);
  }
  else if (
    validateChangePassword.code === 200 && 
    (validateChangePassword.response.code === 401 || validateChangePassword.response.code === 403) && 
    validateChangePassword.response.error && 
    typeof validateChangePassword.response.message === "string" && 
    validateChangePassword.response.message !== ""
  ) {

    NotificationManager.error(validateChangePassword.response.message);
  }
  else {

    NotificationManager.error("Algo sucedió y no fue posible efectuar esta acción, inténtalo nuevamente...");
  }
};
/***********************************************************************************************************************************************/
