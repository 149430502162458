import React, { useContext, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { forgotPassword, initForm } from "./forgot_password_logic";
import Plain from "../layout/containers/Plain";
import LoaderContext from "../layout/shared/loader_context";
import DomainContext from "../domains/domainPathContext";
import Cookies from "js-cookie";

const ForgotPassword = props => {
  const [form, setForm] = useState(initForm);
  const Loader = useContext(LoaderContext);
  const subdomain = useContext(DomainContext);
  const layout = JSON.parse(Cookies.get('layout_03'));

  const onChangeHandler = event => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const onSubmitFormHandler = async e => {
    e.preventDefault();
    Loader.show(true);
    await forgotPassword(form, props, subdomain);
    Loader.show(false);
  };

  return (
    <Plain {...props} noHeader={true} noFooter={true}>
      <Container
        fluid={true}
        className="min-height-100vh bg-size-cover"
        style={{
          backgroundImage: `url(${layout.app_background_undefined_session})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'
        }}
      >
        <Container>
          <Row style={{ minHeight: "100vh" }}>
            <Col
              sm={12}
              md={{ span: 10, offset: 1 }}
              lg={{ span: 8, offset: 2 }}
              xl={{ span: 6, offset: 3 }}
              className="mY-30"
            >
              <Card className="mY-40@sm+">
                <Card.Header>
                  <Container>
                    <Row>
                      <Col
                        xs={12}
                        md={{ span: 10, offset: 1 }}
                        className="pT-20 pB-10 pT-30@sm+ pB-20@sm+"
                      >
                        <h5 className="mb-0 subtitle text-center">
                          Recuperación de Contraseña
                        </h5>
                      </Col>
                    </Row>
                  </Container>
                </Card.Header>
                <Card.Body>
                  <Container>
                    <Row>
                      <Col
                        xs={12}
                        md={{ span: 10, offset: 1 }}
                        className="pT-30"
                      >
                        <Form onSubmit={onSubmitFormHandler}>
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>
                              <b>Email:</b>
                            </Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="Ingresa el correo electrónico"
                              required
                              onChange={onChangeHandler}
                              name="email"
                              value={form.email}
                            />
                            <Form.Text className="text-muted">
                              Ingresa el correo electrónico con el cual
                              registraste la cuenta para proceder con la
                              recuperación de la misma.
                            </Form.Text>
                          </Form.Group>

                          <Row className="btn-group-sign-up pY-20">
                            <Col
                              xs={12}
                              sm={12}
                              className="dinamic-form-tool-bar"
                            >
                              <Button
                                variant="secondary"
                                className="float-left"
                                onClick={() =>
                                  props.history.push(`/${subdomain}/`)
                                }
                              >
                                Cancelar
                              </Button>
                              <Button
                                variant="primary"
                                type="submit"
                                className="float-right"
                              >
                                Enviar
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    </Row>
                  </Container>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>
    </Plain>
  );
};

export default ForgotPassword;
