import React, { useState, useEffect, useContext } from "react";
import { RemotePagination } from "../helper_components/remote_pagination/RemotePagination";
import {
  getPendingProcessData,
  initColumnsProgress,
  executeSearchToProceduresToAttend
} from "./table_process_logic";
import Searcher from "../helper_components/searcher_component/Searcher";
import DomainContext from "../domains/domainPathContext";
import NotificationAlertListContext from "../alertNotificationTable/notification_alert_context";
import {printFunctionaryNotification} from "../alertNotificationTable/alert_notification_logic";
import CountProcessContext from "../layout/shared/count_process_context";
import { Modal } from "react-bootstrap";
import LoaderContext from "../layout/shared/loader_context";
import Loader from "../layout/shared/Loader";

const TableProcessPendingReview = props => {
  const [columns] = useState(initColumnsProgress);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(5);
  const [totalProcedures, setTotalProcedures] = useState(5);
  const subdomain = useContext(DomainContext);
  const alertContext = useContext(NotificationAlertListContext);
  const CountProcess = useContext(CountProcessContext);
  const [ showModal, setShowModal ] = useState(false);
  const [ elementsModal, setElementsModal ] = useState({});
  const [isSearching, setIsSearching] = useState(false);
  const [isCleaning, setIsCleaning] = useState(false);
  const [search, setSearch] = useState("");
  const loader = useContext(LoaderContext);
  const [textNotData, setTextNotData] = useState("");

  useEffect(() => {
    (async function() {
      await getPendingProcessData(
        props,
        setData,
        setTotalProcedures,
        sizePerPage,
        page,
        subdomain,
        CountProcess,
        setShowModal,
        setElementsModal
      );
      if ( alertContext.count > 0 ) {
        printFunctionaryNotification();
      }
    })();
  }, []);

  const handleTableChange = async (type, { page, sizePerPage }) => {
    loader.show(true);
    setPage(page);
    setSizePerPage(sizePerPage);
    if (search.length > 0 && /^$|.*\S+.*/.test(search)) {
      await executeSearchToProceduresToAttend(
        search,
        page,
        sizePerPage,
        setData,
        props,
        setTotalProcedures,
        subdomain,
        CountProcess,
        setShowModal,
        setElementsModal,
        setTextNotData
      );
    }
    else {
      await getPendingProcessData(
        props,
        setData,
        setTotalProcedures,
        sizePerPage,
        page,
        subdomain,
        CountProcess,
        setShowModal,
        setElementsModal
      );
    }
    loader.show(false);
  };

  const getProceduresToSearch = async () => {
    if (search.length > 0 && /^$|.*\S+.*/.test(search)) {

      setIsSearching(true);
      loader.show(true);
      setTextNotData("");
      await executeSearchToProceduresToAttend(
        search,
        1,
        sizePerPage,
        setData,
        props,
        setTotalProcedures,
        subdomain,
        CountProcess,
        setShowModal,
        setElementsModal,
        setTextNotData
      );
      setIsSearching(false);
      loader.show(false);
    }
  };

  const cleanProceduresToSearch = async () => {

    setSearch("");
    setIsCleaning(true);
    loader.show(true);
    await getPendingProcessData(
      props,
      setData,
      setTotalProcedures,
      sizePerPage,
      1,
      subdomain,
      CountProcess,
      setShowModal,
      setElementsModal
    );
    setTextNotData("");
    setIsCleaning(false);
    loader.show(false);  
  };

  return (
    <>

      <Modal show={showModal} size="lg">
        {elementsModal}
      </Modal>

      <h4 className="mB-20 mt-0 w-100 text-center">Trámites por atender</h4>

      <Searcher
        placeholderSearch="Ingresa el texto a buscar"
        placeholderSearchButton="Buscar "
        placeholderCleanButton="Limpiar"
        helperContextual="Ingresa el texto deseado y haz clic en el botón 'Buscar'"
        executeSearch={getProceduresToSearch}
        cleanSearch={cleanProceduresToSearch}
        isSearching={isSearching}
        isCleaning={isCleaning}
        setSearch={setSearch}
        search={search}
        classContainer="classContainer"
        classHelperContextual="classHelperContextual"
        classButtonsSearch="classButtonsSearch"
      />

      { data.length > 0 && textNotData === "" ?
        <RemotePagination
          data={data}
          page={page}
          sizePerPage={sizePerPage}
          totalSize={totalProcedures}
          onTableChange={handleTableChange}
          columns={columns}
        />
        :

        <h4 className="clearFloat text-center mT-20">{textNotData}</h4>
      }

      <Loader/>
    </>
  );
};
export default TableProcessPendingReview;
