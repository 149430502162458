import React, { Component } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Cookies from "js-cookie";

const { domain_name_03 } = Cookies.get();
export default class ErrorCatcher extends Component
{
    /**
     * Constructor.
     * @param props
     */
    constructor(props)
    {
        super(props);
        this.state = { hasError: false };
    }

    /**
     * Catcher error function.
     * @param error
     * @param errorInfo
     */
    componentDidCatch(error, errorInfo)
    {
        this.setState({ hasError: true });
        console.log(error);
    }

    /**
     * Show error page or render children.
     * @returns {React.ReactNode|*}
     */
    render()
    {
        if (this.state.hasError) {
            return (
                <Container className="page-500">
                    <Row>
                        <Col sm={12} lg={6}>
                            <img src={process.env.PUBLIC_URL + "/img/error500.svg"} className="error-image"
                                 alt="errorcito"/>
                        </Col>
                        <Col sm={12} lg={6}>
                            <img src="/logo.svg" className="logo" alt="logo"/>
                            <h1>Error 500</h1>
                            <p>Ups, un error inesperado ha ocurrido y lo que solicitaste no puede ser cumplido.</p>

                            <Button className="btn btn-primary px-4 py-2 mT-20" onClick={() => window.location.replace(`/${domain_name_03}/`)}>Back to home</Button>
                        </Col>
                    </Row>
                </Container>
            );
        }

        try {
            return this.props.children;
        } catch (error) {
            this.setState({ hasError: true });
            console.log(error);
        }
    }
}
