import React, { useState, useEffect, useContext } from "react";
import { Row, Col } from "react-flexbox-grid";
import { Card, Dropdown } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import queryString from "query-string";
import { getStageDetail } from "./static_form_logic";
import OneColumn from "../layout/containers/OneColumn";
import LoaderContext from "../layout/shared/loader_context";
import Loader from "../layout/shared/Loader";
import { xmlHttpRequestManualDownload as httpRequest } from "../user_manual/user_manual_logic";

const StaticForm = props => {
  const [formData, setFormData] = useState([]);
  const getParams = queryString.parse(props.history.location.search);
  const loader = useContext(LoaderContext);

  useEffect(() => {
    (async function() {
      loader.show(true);
      await getStageDetail(getParams, setFormData);
      loader.show(false);
    })();
  }, []);

  let pageConfig = {
    active: "traking",
    title: "Seguimiento"
  };

  const downloadPDF = (el) => {
    httpRequest(el['value'], function (blob) {
      let a = document.createElement('a');
      let blobb = new Blob([blob], {type: 'application/pdf'});
      let url = URL.createObjectURL(blobb);
      const name = el['label'] + ".pdf";
      a.href = url;
      a.download = name;
      a.click();  
    });
  };

  const formatDate = DateString => {
    let date = new Date(DateString);
    let month = date.getMonth() + 1;
    return (
      date.getDate() +
      "-" +
      (month < 9 ? "0" : "") +
      month +
      "-" +
      date.getFullYear()
    );
  }
  
  const downloadFile = (el) => {
    httpRequest(el['value'], function (blob) {
      let a = document.createElement('a');
      let blobb = new Blob([blob], {type: blob.type});
      let ex = blob.type;
      let ext = ex.split("/");
      let url = URL.createObjectURL(blobb);
      const name = el['label'] + "." + ext[1];
      a.href = url;
      a.download = name;
      a.click();  
    });
  };

  const makeFormBasedFields = fields => {
    const formFields = fields.map((el, index) => {
      if (typeof el.value == "object"  && el.value.length >= 0) {
        if ( el.type === "grid" ) {
          var dataIndex = el.value;
          var columns = [];
          var data = [];
          
          dataIndex.forEach((elements, index) => {            

            var arrayRender = [];
            
            elements.forEach((element, i)=> {
              if ( element.type !== "action" && index === 0 ) {
                columns.push({ dataField: element.description, text: element.description })
              }
              if( element.type === 'text' || element.type === 'check' ) {
                arrayRender[element.description] = element.value; 
              } else if ( element.type === 'select' ) {
                arrayRender[element.description] = element.value ? element.value.label : '';
              } else if ( element.type === 'date' ) {
                arrayRender[element.description] = formatDate(element.value);
              }
            })

            data.push(arrayRender)
          });

          return (
            <tr key={index}>
              <td colSpan={2}>
                <h4 className="mY-20 text-center">{el.label}</h4>
                <BootstrapTable
                  remote
                  keyField="id"
                  data={data}
                  columns={columns}
                  className="overflow-hidden w-100"
                />
               </td>
            </tr>
          )
        }

        return (
          <tr key={index}>
            <td
              className="font-weight-bold text-right"
              style={{ width: "40%" }}
            >
              {el.label}:
            </td>
            <td className="break-word text-justify">
              {el.value.map((option, indexOption) => {
                let options = "";

                // add options selected
                for (let key in option) {
                  options = option[key] ? key + ", " : "";
                }

                return options;
              })}
            </td>
          </tr>
        );
      } else if (String(el.value).includes("http")) {
        return (
          <tr key={index}>
            <td
              className="font-weight-bold text-right"
              style={{ width: "40%" }}
            >
              {el.label}:
            </td>
            <td className="break-word text-justify">
              <button onClick={() => downloadFile(el)} className="btn btn-primary" >
                <i className="ti-download mr-2" title="Descargar" />
                Descargar
              </button>
            </td>
          </tr>
        );
      } else if (
        typeof el.value === 'object' && 
        el.value.url && 
        typeof el.value.url === 'string' &&
        el.value.url.includes('http') && 
        el.type === 'digital-document'
      ) {
        return (
          <tr key={index}>
            <td
              className="font-weight-bold text-right"
              style={{ width: "40%" }}
            >
              {el.label}:
            </td>
            <td className="break-word text-justify">
              <button onClick={() => downloadPDF({label:el.label, value:el.value.url})} className="btn btn-primary">
                <i className="ti-download mr-2" title="Descargar" />
                Descargar
              </button>
            </td>
          </tr>
        );
      } else if (
        typeof el.value === 'object' && 
        el.value.url && 
        typeof el.value.url !== 'string' &&
        el.value.url.length > 0 &&
        el.type === 'digital-document'
      ) {
        return (
          <tr key={index}>
            <td
              className="font-weight-bold text-right"
              style={{ width: "40%" }}
            >
              {el.label}:
            </td>
            <td className="break-word text-justify">
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  {'Descargar documento(s)'}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {el.value.url.map((file, i) => (
                    <Dropdown.Item 
                      key={i} 
                      href={file['url']}
                    >
                      {'Documento # ' + (i + 1) + ' (' + file['type'] + ')'}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </td>
          </tr>
        );
      }

      return (
        <tr key={index}>
          <td className="font-weight-bold text-right" style={{ width: "40%" }}>
            {el.label}:
          </td>
          <td className="break-word text-justify">{el.value}</td>
        </tr>
      );
    });
    return formFields;
  };

  const makeFormsBasedResponse = form => { 
    if (form.length > 0) {
      const responseFormFormatted = form.map((el, index) => {
        return (
          <Col key={index} sm>
            <Card className="mB-20">
              <Card.Body>
                <div>
                  <table className="nilo w-100">
                    <thead>
                      <tr>
                        <th colSpan={2} className="text-center p-20">
                          <h4 className="mb-0">{el.name}</h4>
                        </th>
                      </tr>
                    </thead>
                    <tbody>{makeFormBasedFields(el.fields)}</tbody>
                  </table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        );
      });
      return responseFormFormatted;
    } else {
      return (
        <div className="alert alert-warning" role="alert">
          La etapa no se encuentra vinculada a ningún trámite, favor de intentarlo
          de nuevo más tarde.
        </div>
      );
    }
  };

  return (
    <OneColumn
      {...props}
      pageConfig={pageConfig}
      hideTopMenu={true}
      noTitle={true}
    >
      <section className="tracking">
        <Row className="pB-40">
          <Col xs={12}>
            <h3 className="mY-20 text-center">Seguimiento de Etapa</h3>
          </Col>
        </Row>
        { loader.status ? 
          <Loader/>
        :
          <Row className="pB-40">{makeFormsBasedResponse(formData)}</Row>
        }
      </section>
    </OneColumn>
  );
};

export default StaticForm;

