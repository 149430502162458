import React, {useState} from "react";
import {Grid} from "react-flexbox-grid";
import NavBar from "../shared/NavBar";
import TopMenu from "../shared/TopMenu";
import Footer from "../shared/Footer";

const OneColumn = props => {
    const [logo, setLogo] = useState('');

    const setHeaderLogo = (path) => {
        setLogo(path);
    };

    const getTabs = () => {
        return !props.hideTopMenu ? (<TopMenu countProcess={props.countProcess} setCountProcess={props.setCountProcess} pageConfig={props.pageConfig}/>) : '';
    };

    const getTitle = () => {
        return !props.noTitle ? (
            <Grid className="pT-40 pB-20 title">
                <h3 className="text-center">Bienvenido a tu buzón personal</h3>
            </Grid>
        ) : '';
    };

    return (
        <div className="one_column">
            <NavBar logo={logo} setHeaderLogo={setHeaderLogo} {...props} />

            { getTitle() }

            <Grid fluid={true} className="no-padding" style={{minHeight: '76vh'}}>
                { getTabs() }

                <Grid fluid={true} className="principal-container no-padding">
                    <Grid fluid={props.fluid} className={props.fluid ? 'p-0': ''}>{props.children}</Grid>
                </Grid>
            </Grid>
            <Footer setHeaderLogo={setHeaderLogo} />
        </div>
    );
};

export default OneColumn;