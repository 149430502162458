import React from "react";
import CheckboxReadAlert from "./CheckboxReadAlert";
import Cookies from "js-cookie";
import { Request } from "../classes/Request";
import { NotificationManager } from "react-notifications";

export const columns = [
  {
    dataField: "id",
    text: "Id",
    sort: true
  },
  {
    dataField: "procedure_id",
    text: "Trámite",
    sort: true
  },
  {
    dataField: "operation",
    text: "Alerta",
    sort: true
  },
  {
    dataField: "created_at",
    text: "Fecha de creación",
    sort: true
  },
  {
    dataField: "read",
    text: "Marcar como leído",
    sort: true
  }
];

//FORMAT TABLE WITH THE JSX CHECKBOX AND CORRECT HTML
export const formatDataTable = async (data, updateRead, updateReadCheck) => {
  //updateRead: FUNCTION THAH UPDATE THE CHECKBOX WITHOUT RELOADING THE PAGE
  //updateReadCheck: ACTUAL VALUE OF THE FLAG, FOR CHECKING UPDATE VALUE OF CHECKBOX

  let newArrayFormated = data;
  newArrayFormated.forEach(el => {
    if (!el.read) {
      el.read = (
        <CheckboxReadAlert
          alertId={el.id}
          updateReadValue={updateRead}
          actualReadValue={updateReadCheck}
        />
      );
    }
  });
  return newArrayFormated;
};

/**********************************************************FORGOT PASSWORD************************************************************************/
export const updateAlertReadStatus = async (props, read, alertContext) => {
  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/alerts/${props.alertId}`,
    "put",
    null,
    { token: process.env.REACT_APP_TOKEN, read: read }
  );
  const updateAlertData = await request.executeRequest();
  if (updateAlertData.code === 200) {
    props.updateReadValue(!props.actualReadValue);
    //THIS LINE MAKE THE BADGE COUNT ALERT RENDER
    alertContext.updateCount(!alertContext.requireUpdate, "substractAlert");
    //MOSTRAR ALERTA EXITOSA CON EL MENSAJE QUE RETORNA LA API COMPONENTE

    if (
      typeof updateAlertData.data.data.data.message == "string" &&
      updateAlertData.data.data.data.message !== ""
    )
      NotificationManager.success(updateAlertData.data.data.data.message);
  }
};
/******************************************************************************************************************************************************/

/**********************************************************FORGOT PASSWORD************************************************************************/
export const getAllAlertNotifications = async form => {
  const { userId_03 } = Cookies.get();
  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/unread_alerts/${userId_03}?token=${process.env.REACT_APP_TOKEN}`,
    "get",
    null,
    {}
  );
  const getNotificationData = await request.executeRequest();
  if (getNotificationData.code !== 200) {
    //MOSTRAR ALERTA EXITOSA CON EL MENSAJE QUE RETORNA LA API COMPONENTE
    return [];
  }

  return getNotificationData.data.data.data.alerts;
};

export const printFunctionaryNotification = async () => {
  
  return NotificationManager.warning(
    "Tienes notificaciones sin leer",
    "Estimado usuario: ",
    1800
  );
};

export const updateAllAlertNotifications = async (alertContext, userIdLogin) => {
  let { userId_03 } = Cookies.get();
  userId_03 = userIdLogin ? userIdLogin : userId_03;

  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/unread_alerts/${userId_03}?token=${process.env.REACT_APP_TOKEN}`,
    "get",
    null,
    {}
  );
  const getNotificationData = await request.executeRequest();

  if (getNotificationData.code === 200) {
    if(getNotificationData.data.data.data.alerts.length > 0){
      alertContext.updateCount(true, "sumAlert",getNotificationData.data.data.data.alerts.length);
      NotificationManager.warning(
        getNotificationData.data.data.data.alerts.length === 1 ?
        "Cuentas con 1 notificación pendiente" :
        `Cuentas con ${getNotificationData.data.data.data.alerts.length} notificaciones pendientes`
      );
    }
  }

};