/**************************************************LIBARY IMPORTS******************************************************/
import React, { useEffect, useState, useContext } from "react";
import { Button, Form, Image, Modal } from "react-bootstrap";
import {
  evaluateVisibility,
  finishLastStep,
  settingDefaultValues,
  validateOnChangeValues,
  isImage,
  iconUrl,
  iconUrlByExt,
} from "./dynamic_form_logic";

import { Col, Grid, Row } from "react-flexbox-grid";
import DynamicMap from "../helper_components/dynamic_map/DynamicMap";
import DynamicSelect from "../helper_components/dynamic_select/DynamicSelect";
import Cookies from "js-cookie";
import _ from "lodash";
import ObservationModal from "./observations/ObservationsModal";
/************************************************************************************************************************/
import DigitalSignature from "../helper_components/digital_signature/DigitalSignature";
import DigitalDocument from "../helper_components/digital_document/DigitalDocument";
import DatePicker from "../helper_components/date_picker/DatePicker";
import DomainContext from "../domains/domainPathContext";
import NotificationAlertListContext from "../alertNotificationTable/notification_alert_context";
import { updateAllAlertNotifications } from "../alertNotificationTable/alert_notification_logic";
import Muuri from "../muuri/Muuri";
import GridField from "../helper_components/grid/GridField";
import LoaderContext from "../layout/shared/loader_context";
import Loader from "../layout/shared/Loader";
import DynamicFile from "../helper_components/dynamic_file/DynamicFile";

const DynamicForm = props => {
  const [state, setState] = useState(state);
  const [dataForm, setDataForm] = useState([]);
  const [previousUrl, setPreviousUrl] = useState("");
  const [observationModal, setObservationModal] = useState(false);
  const [previewFileImage, setPreviewFileImage] = useState(false);
  const [typeViewFile, setTypeViewFile] = useState(false);
  const [previewFileImgUrl, setPreviewImgUrl] = useState("");
  const [buttonToDisabled, setButtonToDisabled] = useState("");
  const [activeElementValidable, setActiveElementValidable] = useState({});
  const [disableUploadButton, setDisableUploadButton] = useState(false);
  const subdomain = useContext(DomainContext);
  const [nextButtonDisable, setNextButtonDisable] = useState(false);
  const alertContext = useContext(NotificationAlertListContext);
  const LoaderStatus = useContext(LoaderContext);
  const [filesWithHeaders, setFilesWithHeaders] = useState([]);
  let selectsWithDependentSelects = {};
  const [temporaryUrls, setTemporaryUrls] = useState([]);

  useEffect(() => {
    //ORDENAMOS EL FORMULARIO POR LA POSISION Y DEL FORMULARIO
    setPreviousUrl(props.previousUrl);
    const formsSort = props.form;
    let defaultValuesFormInit = {};
    setNextButtonDisable(false);

    if (!props.hasErrors) {
      setNextButtonDisable(false);
      defaultValuesFormInit = settingDefaultValues(
        formsSort,
        setNextButtonDisable,
        props.mode,
      );
      setState(defaultValuesFormInit);
    }

    // check visibility fields
    const formEvaluated = evaluateVisibility(
      formsSort,
      defaultValuesFormInit,
      null,
      null,
      null,
    );
    //GUARDAMOS EN EL STATE EL VALOR DEL FORMULARIO QUE PASAMOS COMO PROP
    if (formEvaluated.length > 0) {
      setDataForm(formEvaluated);
    }
  }, [props.form, dataForm]);

  //CUANDO LE DA CLICK EN CONTINUAR
  const onSubmit = e => {
    e.preventDefault();
    if (props.onSubmit) {
      destroyTemporaryURLS();
      setButtonToDisabled('next');
      props.onSubmit(state);
    }
  };

  //CUANDO EL USUARIO LE DA CLICK EN GUARDAR
  const onSaveHandler = e => {
    //Request para el siguiente formulario
    e.preventDefault();
    if (props.onSubmit) {
      setButtonToDisabled('save');
      props.onSubmit(state, true); 
    }
  };

  //CUANDO LE DA CLICK EN ANTERIOR
  const onPreviousHandler = e => {
    e.preventDefault();
    if (previousUrl) {
      destroyTemporaryURLS();
      setState({});
      props.setLastStep(false);
      props.setHasErrors(false);
      props.previousUrlHandler(previousUrl);
    }
  };

  const destroyTemporaryURLS = () => {
    let urlsDelete = filesWithHeaders.map((file) => file['url']);
    urlsDelete = urlsDelete.concat(temporaryUrls);
    urlsDelete.forEach((url) => {
      URL.revokeObjectURL(url);
    });
  };

  const onChangeListCheckboxHandler = (event, checkbox, element) => {
    let nameCheckboxSelected = "";
    let valueCheckboxSelected = false;
    const newListCheckbox = state[element.name];
    const nameState = element.name;
    let checkName = event.target.name;
    const foundItem = newListCheckbox.map(e => Object.keys(e)).filter(e => e.includes(checkName))

    if (foundItem.length === 0) {
      newListCheckbox.push({ [event.target.name]:event.target.checked });
    }

    newListCheckbox.forEach(check => {
      if (typeof check[checkbox.name] !== "undefined") {
        nameCheckboxSelected = event.target.name;
        valueCheckboxSelected = event.target.checked;
        check[checkbox.name] = event.target.checked;
      }
    });

    setState({ ...state, [nameState]: newListCheckbox });
    evaluateVisibility(
      dataForm,
      state,
      valueCheckboxSelected,
      nameCheckboxSelected,
      "checkbox-list",
      element.name,
    );

  };

  const onChangeHandler = (
    e, key, type = null, 
    errorDate = null, dataToSetDependentSelects = null) => {
    const valueInput = e;
    const elementName = key;
    const elementType = type;
    const elementErrorDate = errorDate;
    validateOnChangeValues(
      valueInput,
      elementName,
      elementType,
      elementErrorDate,
      state,
      setState,
      dataForm,
      setDataForm,
      dataToSetDependentSelects
    );
  };

  const onObservationClickHandler = async el => {
    setActiveElementValidable(el);
    setObservationModal(true);
  };

  //CUANDO DAN CLICK AL BOTON DE CONFIRMAR EN EL ULTIMO FORMULARIO
  const finishStage = async () => {
    const { userId_03 } = Cookies.get();
    const stage_id = props.stage;
    LoaderStatus.show(true);
    setButtonToDisabled('finish');
    await finishLastStep(
      stage_id,
      userId_03,
      state.next_user,
      props,
      subdomain
    );
    LoaderStatus.show(false);
    await updateAllAlertNotifications(alertContext);
  };

  const setErrorByElement = el => {
    const errorMessages = el.error
      ? el.error.map((el, index) => {
          return (
            <li key={index}>
              <small className="text-danger">{el}</small>
            </li>
          );
        })
      : "";
    return errorMessages;
  };

  const createInputBaseTypeForm = el => {
    const disableFieldBasedMethod = el => {
      const { userId_03 } = Cookies.get();

      if (!el.readonly || el.readonly === 0) {
        if (props.mode === 2) {
          return true;
        }
        if (props.mode === 1) {
          return false;
        } else {
          if (parseInt(userId_03) !== parseInt(props.applicant)) {
            return true;
          } else {
            if (el.observations) {
              return false;
            } else {
              return true;
            }
          }
        }
      }
      if (el.readonly === 1) {
        if (props.mode === 2) {
          return true;
        }
        if (props.mode === 1) {
          return true;
        } else {
          if (parseInt(userId_03) !== parseInt(props.applicant)) {
            return true;
          } else {
            if (el.observations) {
              return false;
            } else {
              return true;
            }
          }
        }
      }
    };
    switch (el.type) {
      case "text":
        const errorsInputText = setErrorByElement(el);
        return (
          <>
            <Col
              className={"" + (el.visibility["status"] ? "" : " d-none")}
              xs={12}
              md={el.width}
            >
              <Form.Group controlId={`inputForm.${el.name}`}>
                <Form.Label
                  className={
                    "font-weight-bold " + (el.error ? "text-danger" : "")
                  }
                >
                  <span
                    style={
                      el.requiredSignal
                        ? { display: "inline" }
                        : { display: "none" }
                    }
                  >
                    <b>* </b>
                  </span>
                  {el.label}
                  <span
                    className="observation-input-icon"
                    style={{
                      display:
                        el.is_validable && props.mode === 3
                          ? "inline-block"
                          : "none",
                    }}
                    onClick={() => onObservationClickHandler(el)}
                  >
                    <i
                      className={
                        "far fa-comment-dots" +
                        (el.observations ? " text-danger" : "")
                      }
                    />
                  </span>
                </Form.Label>
                <Form.Control
                  type={el.type}
                  disabled={disableFieldBasedMethod(el)}
                  name={el.name}
                  key={el.id}
                  onChange={e => onChangeHandler(e, el.name)}
                  value={state[el.name] || ""}
                  className={el.error ? "is-invalid" : ""}
                />
                {errorsInputText}
                <Form.Text className="text-muted">
                  {el.contextual_help ? el.contextual_help : ""}
                </Form.Text>
              </Form.Group>
            </Col>
          </>
        );
      case "title":

        return (
          <>
            <Col
              className={"" + (el.visibility["status"] ? "" : " d-none")}
              xs={12}
              md={el.width}
            >
             <div
              className="styles-title-field"
              dangerouslySetInnerHTML={{
                __html: el.label
              }}
              id={el.id}
            />
            </Col>
          </>
        );
      case "textarea":
        const errorsTextArea = setErrorByElement(el);
        const textareaHeight = el.height * 0.79 + "rem";
        return (
          <Col
            className={
              "h-100 mb-0" + (el.visibility["status"] ? "" : " d-none")
            }
            xs={12}
            md={el.width}
          >
            <Form.Group controlId={`inputForm.${el.name}`}>
              <Form.Label
                className={
                  "font-weight-bold " + (el.error ? "text-danger" : "")
                }
              >
                <span
                  style={
                    el.requiredSignal
                      ? { display: "inline" }
                      : { display: "none" }
                  }
                >
                  <b>* </b>
                </span>
                {el.label}
                <span
                  className="observation-input-icon"
                  style={{
                    display:
                      el.is_validable && props.mode === 3
                        ? "inline-block"
                        : "none",
                  }}
                  onClick={() => onObservationClickHandler(el)}
                >
                  <i
                    className={
                      "far fa-comment-dots" +
                      (el.observations ? " text-danger" : "")
                    }
                  />
                </span>
              </Form.Label>
              <Form.Control
                as={el.type}
                rows="3"
                name={el.name}
                disabled={disableFieldBasedMethod(el)}
                key={el.id}
                onChange={e => onChangeHandler(e, el.name)}
                value={state[el.name] || ""}
                className={el.error ? "is-invalid" : ""}
                style={{ height: textareaHeight, resize: "none" }}
              />
              {errorsTextArea}
              <Form.Text className="text-muted">
                {el.contextual_help ? el.contextual_help : ""}
              </Form.Text>
            </Form.Group>
          </Col>
        );
      case "check-box":
        const errorsCheckbox = setErrorByElement(el);
        const isMultipleCheckbox = _.has(el.extra_attributes, "check");
        //SI ES UN CHECK-BOX PERO ES UNA LISTA DE CHECKBOX
        if (isMultipleCheckbox) {
          let checks = {};
          const list = el.extra_attributes.check.map((element, index) => {
            // eslint-disable-next-line array-callback-return
            (state[el.name] ? state[el.name] : []).find(check => {
              checks = { ...checks, ...check };
            });

            return (
              <div className="form-check" key={index}>
                <input
                  type="checkbox"
                  className={
                    element.error
                      ? "form-check-input is-invalid"
                      : "form-check-input"
                  }
                  disabled={disableFieldBasedMethod(el)}
                  id={element.name}
                  key={element.id}
                  name={element.name}
                  checked={checks[element.name]}
                  onChange={event =>
                    onChangeListCheckboxHandler(event, element, el)
                  }
                />
                <label
                  className={
                    el.error
                      ? "form-check-label text-danger"
                      : "form-check-label"
                  }
                  htmlFor={element.name}
                >
                  {element.label}
                </label>
              </div>
            );
          });
          return (
            <Col
              className={el.visibility["status"] ? "p-10" : "p-10 d-none"}
              xs={12}
              md={el.width}
            >
              <Col xs={12}>
                <div className="form-group mb-2">
                  <label
                    className={
                      "font-weight-bold " +
                      (el.error
                        ? "form-check-label text-danger"
                        : "form-check-label")
                    }
                    htmlFor={`${el.name}_checkboxlist_label`}
                  >
                    <span
                      style={
                        el.requiredSignal
                          ? { display: "inline" }
                          : { display: "none" }
                      }
                    >
                      <b>* </b>
                    </span>
                    {el.label}
                    <span
                      className="observation-input-icon"
                      style={{
                        display:
                          el.is_validable && props.mode === 3
                            ? "inline-block"
                            : "none",
                      }}
                      onClick={() => onObservationClickHandler(el)}
                    >
                      <i
                        className={
                          "far fa-comment-dots" +
                          (el.observations ? " text-danger" : "")
                        }
                      />
                    </span>
                  </label>
                </div>
              </Col>

              <Col xs={12} sm={12}>
                {list}
              </Col>
              <Col xs={12} sm={12}>
                <small
                  id={`small${el.name}`}
                  className="form-text text-muted mt-1"
                >
                  {el.contextual_help ? el.contextual_help : ""}
                </small>
              </Col>
              {errorsCheckbox}
            </Col>
          );
        } else {
          //SI ES UN CHECK-BOX SINGULAR, SOLAMENTE UN CAMPO DECLARADO EN EL MODELADO
          return (
            <Col
              xs={12}
              md={el.width}
              className={
                "form-group row p-2 pt-4 h-100 mb-0" +
                (el.visibility["status"] ? "" : " d-none")
              }
            >
              <div className="col-sm-10">
                <label
                  className="font-weight-bold form-check-label"
                  htmlFor={`${el.name}_checkbox_label`}
                >
                  <span
                    style={
                      el.requiredSignal
                        ? { display: "inline" }
                        : { display: "none" }
                    }
                  >
                    <b>* </b>
                  </span>
                  {el.label}
                  <span
                    className="observation-input-icon"
                    style={{
                      display:
                        el.is_validable && props.mode === 3
                          ? "inline-block"
                          : "none",
                    }}
                    onClick={() => onObservationClickHandler(el)}
                  >
                    <i
                      className={
                        "far fa-comment-dots" +
                        (el.observations ? " text-danger" : "")
                      }
                    />
                  </span>
                </label>
                <div className="form-check">
                  <input
                    className={
                      el.error
                        ? "form-check-input is-invalid"
                        : "form-check-input"
                    }
                    type="checkbox"
                    disabled={disableFieldBasedMethod(el)}
                    id={el.name}
                    key={el.id}
                    name={el.name}
                    checked={state[el.name] || false}
                    onChange={e => onChangeHandler(e, el.name, el.type)}
                    required
                  />
                  <label
                    className={
                      el.error
                        ? "form-check-label text-danger"
                        : "form-check-label"
                    }
                    htmlFor={el.name}
                  >
                    {el.label}
                  </label>
                </div>
                <small id={`small${el.name}`} className="form-text text-muted">
                  {el.contextual_help ? el.contextual_help : ""}
                </small>
                {errorsCheckbox}
              </div>
            </Col>
          );
        }

      case "subtitle":
        return (
          <Col
            xs={12}
            md={el.width}
            className={"" + (el.visibility["status"] ? "" : " d-none")}
          >
            <h6 
              className="styles-subtitle-field"
              key={el.id} 
              id={el.id}>
                {el.label}
            </h6>
          </Col>
        );

      case "radio-button":
        const errorsRadio = setErrorByElement(el);
        return (
          <Col
            xs={12}
            className={"" + (el.visibility["status"] ? "" : " d-none")}
          >
            <div>
              <label className="font-weight-bold mb-0">
                <span
                  style={
                    el.requiredSignal
                      ? { display: "inline" }
                      : { display: "none" }
                  }
                >
                  <b>* </b>
                </span>
                {el.label}
              </label>

              <span
                className="observation-input-icon"
                style={{
                  display:
                    el.is_validable && props.mode === 3
                      ? "inline-block"
                      : "none",
                }}
                onClick={() => onObservationClickHandler(el)}
              >
                <i
                  className={
                    "far fa-comment-dots" +
                    (el.observations ? " text-danger" : "")
                  }
                />
              </span>
            </div>

            {el.extra_attributes.options.map((option, index) => {
              return (
                <Col xs={12} md={el.width} key={index}>
                  <div className="form-check">
                    <input
                      className={
                        el.error
                          ? "form-check-input is-invalid"
                          : "form-check-input"
                      }
                      disabled={disableFieldBasedMethod(el)}
                      type="radio"
                      name={el.name}
                      id={`${option.label}-radio`}
                      checked={option.value === state[el.name] ? true : false}
                      onChange={e =>
                        onChangeHandler(e, el.name, el.type, option.value)
                      }
                    />
                    <label
                      className={
                        el.error
                          ? "form-check-label text-danger"
                          : "form-check-label"
                      }
                      htmlFor={`${option.label}-radio`}
                    >
                      {option.label}
                    </label>
                  </div>
                </Col>
              );
            })}

            <Col xs={12} sm={12}>
              <small
                id={`small${el.name}`}
                className="form-text text-muted mt-1"
              >
                {el.contextual_help ? el.contextual_help : ""}
              </small>
            </Col>
            {errorsRadio}
          </Col>
        );

      case "paragraph":
        return (
          <>
            <Col
              className={
                "h-100 mb-0 mT-10 overflow-auto paragraph-wrapper" +
                (el.visibility["status"] ? "" : " d-none")
              }
              xs={12}
              md={el.width}
            >
              <label className="font-weight-bold">{el.label}</label>
              <p
                className="break-word overflow-y-scroll"
                dangerouslySetInnerHTML={{
                  __html: el.extra_attributes.content,
                }}
              />
            </Col>
          </>
        );

      case "redirect":
        return (
          <>
            <Col 
              className={el.visibility["status"] ? "inline-block" : " d-none"}
              xs={12}
              md={el.width}
            >
              <label className="font-weight-bold">{el.label}</label>
              <div>
                <Button>
                  <a
                    href={el.extra_attributes.url}
                    target={el.extra_attributes.target}
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    {el.extra_attributes.url_label}
                  </a>
                </Button>
              </div>
              <div className="w-100">
                <small id="redirectHelp" className="form-text text-muted">
                  {el.contextual_help ? el.contextual_help : ""}
                </small>
              </div>
            </Col>
          </>
        );

      case "hidden":
        return (
          <>
            <Form.Group controlId={`inputForm.${el.name}`}>
              <Form.Control
                type="text"
                name={el.name}
                key={el.id}
                value={el.default_value ? el.default_value : ""}
                onChange={() => {}}
                style={{ visibility: "hidden" }}
              />
            </Form.Group>
          </>
        );

      case "file":
        const { userId_03 } = Cookies.get();
        const queryParamDocumentManager = el.value && el.value.value && el.value.value.access_key;
        const isImageUploadDocumentManager = state[el.name + '_isUploadToDocumentManager'];
        let fileData = {
          route: state[el.name + "_img"]
            ? state[el.name + "_img"]
            : el.file_route,
          extension: state[el.name + "_ext"]
            ? state[el.name + "_ext"]
            : el.format,
          status: state[el.name + "_status"]
            ? state[el.name + "_status"]
            : el.file_route
            ? 2
            : 0,
          isImage: isImage(
            state[el.name + "_ext"] ? state[el.name + "_ext"] : el.format,
          ),
          icon: state[el.name + "_ext"]
            ? iconUrl(state, el.name)
            : iconUrlByExt(el.format),
        };
        // ADD QUERY PARAMS TO SHOW WATERMARK
        fileData['route'] = fileData['route'] && el['extra_attributes']['show_watermark'] ? 
          fileData['route'] + '&user_id=' + userId_03 : fileData['route'];
        // ADD QUERY PARAMS TO DOCUMENT MANAGER (SOLTEIN)
        fileData['route'] = queryParamDocumentManager && !isImageUploadDocumentManager ? fileData['route'] + '&access_token=' + queryParamDocumentManager : fileData['route'];
        return (
          <DynamicFile
            el={el} 
            onObservationClickHandler={onObservationClickHandler} 
            disableFieldBasedMethod={disableFieldBasedMethod}
            fileData={fileData}
            disableUploadButton={disableUploadButton}
            setDisableUploadButton={setDisableUploadButton}
            state={state}
            setState={setState}
            props={props}
            LoaderStatus={LoaderStatus}
            filesWithHeaders={filesWithHeaders}
            setFilesWithHeaders={setFilesWithHeaders}
            temporaryUrls={temporaryUrls}
            setTemporaryUrls={setTemporaryUrls}
            setPreviewFileImage={setPreviewFileImage}
            setTypeViewFile={setTypeViewFile}
            setPreviewImgUrl={setPreviewImgUrl}
          />
        );
      case "date":
        const errorsCalendar = setErrorByElement(el);
        return (
          <DatePicker
            el={el}
            hasErrors={el.error}
            errorsCalendar={errorsCalendar}
            onChangeHandler={onChangeHandler}
            state={state}
            setState={setState}
            mode={props.mode}
            setShow={onObservationClickHandler}
            show={observationModal}
            applicant={props.applicant}
          />
        );

      case "map":
        const errorsMap = setErrorByElement(el);

        if (el.visibility.status)
          return (
            <DynamicMap
              el={el}
              onChangeHandler={onChangeHandler}
              hasErrors={el.error}
              errorsMap={errorsMap}
              state={state}
              setState={setState}
              mode={props.mode}
              setShow={onObservationClickHandler}
              show={observationModal}
              applicant={props.applicant}
            />
          );
      break;

      case "select":
        const errorSelect = setErrorByElement(el);
        return (
          <DynamicSelect
            el={el}
            onChangeHandler={onChangeHandler}
            hasErrors={el.error}
            errorSelect={errorSelect}
            state={state}
            setState={setState}
            mode={props.mode}
            setShow={onObservationClickHandler}
            show={observationModal}
            applicant={props.applicant}
            selectsWithDependentSelects={selectsWithDependentSelects}
          />
        );

      case "digital-signature":
        const errorDigital = setErrorByElement(el);
        return (
          <DigitalSignature
            el={el}
            onChangeHandler={onChangeHandler}
            hasErrors={el.error}
            errorDigital={errorDigital}
            state={state}
            setState={setState}
            mode={props.mode}
            stage={props.stage}
            showModalSignature=""
            showDowloadDocument=""
            finalDocumentUrlPDF=""
            setFinalDocumentUrlPDF=""
            setSignInStep=""
          />
        );

      case "digital-document":
        const errorDocument = setErrorByElement(el);
        return (
          <DigitalDocument
            el={el}
            onChangeHandler={onChangeHandler}
            hasErrors={el.error}
            errorDocument={errorDocument}
            state={state}
            setState={setState}
            mode={props.mode}
            stage={props.stage}
            setNextButtonDisable={setNextButtonDisable}
          />
        );

      case "grid":
        return (
          <Col
            className={"" + (el.visibility["status"] ? "" : " d-none")}
            xs={12}
            md={el.width}
          >
            <GridField
              el={el}
              applicant={props.applicant}
              onChangeHandler={() => onChangeHandler}
              state={state}
              setState={setState}
              mode={props.mode}
              stage={props.stage}
              setNextButtonDisable={setNextButtonDisable}
            />
          </Col>
        );

      default:
        break;
    }
  };

  const buttonToolbar = () => {
    if (!props.lastStep) {
      if (!props.previousUrl) {
        return (
          <Grid className="mT-20 dynamic-form-toolbar container">
            <Row>
              <div className="p-100 w-100">
                <span className="form-text text-muted text-center">
                  <b>( * ) Campos requeridos.</b>
                </span>
              </div>
              <Col xs={12} md={12}>
                <Button
                  variant="dark"
                  type="button"
                  onClick={() =>
                    props.history.push(`/${subdomain}/table_process/pending`)
                  }
                >
                  Cancelar
                </Button>
                <Button
                  variant="success"
                  type="button"
                  className="d-block float-right px-4"
                  onClick={onSubmit}
                  disabled={nextButtonDisable || (LoaderStatus.status && buttonToDisabled === 'next')}
                >
                  {LoaderStatus.status && buttonToDisabled === 'next' ? 
                  'Ejecutando...' : 
                  'Siguiente'}
                </Button>
                <Button
                  variant="primary"
                  type="button"
                  className="d-block float-right px-4 mR-20"
                  onClick={onSaveHandler}
                  disabled={LoaderStatus.status && buttonToDisabled === 'save'}
                >
                  {LoaderStatus.status && buttonToDisabled === 'save' ? 
                  'Guardando...' : 
                  'Guardar'}
                </Button>
              </Col>
            </Row>
          </Grid>
        );
      } else {
        return (
          <Grid className="mT-20 dynamic-form-toolbar container">
            <Row>
              <div className="p-100 w-100">
                <span className="form-text text-muted text-center">
                  <b>( * ) Campos requeridos.</b>
                </span>
              </div>
              <Col xs={12} md={12}>
                <Button
                  variant="dark"
                  type="button"
                  className="d-block float-left px-4"
                  onClick={() =>
                    props.history.push(`/${subdomain}/table_process/pending`)
                  }
                >
                  Cancelar
                </Button>
                <Button
                  variant="secondary"
                  type="button"
                  className="d-block float-left px-4 mL-20"
                  onClick={onPreviousHandler}
                >
                  Anterior
                </Button>
                <Button
                  variant="success"
                  type="button"
                  className="d-block float-right px-4"
                  onClick={onSubmit}
                  disabled={nextButtonDisable || (LoaderStatus.status && buttonToDisabled === 'next')}
                >
                  {LoaderStatus.status && buttonToDisabled === 'next' ? 
                  'Ejecutando...' : 
                  'Siguiente'}
                </Button>
                <Button
                  variant="primary"
                  type="button"
                  className="d-block float-right px-4 mR-20"
                  onClick={onSaveHandler}
                  disabled={LoaderStatus.status && buttonToDisabled === 'save'}
                >
                  {LoaderStatus.status && buttonToDisabled === 'save' ? 
                  'Guardando...' : 
                  'Guardar'}
                </Button>
              </Col>
            </Row>
          </Grid>
        );
      }
    } else {
      return (
        <Grid className="mT-20 dynamic-form-toolbar container">
          <Row end="xs">
            <Col xs={12} md={12}>
              <Button
                variant="dark"
                type="button"
                className="d-block float-left px-4"
                onClick={() =>
                  props.history.push(`/${subdomain}/table_process/pending`)
                }
              >
                Cancelar
              </Button>
              <Button
                variant="secondary"
                type="button"
                className="d-block float-left px-4 mL-20"
                onClick={onPreviousHandler}
              >
                Anterior
              </Button>
              <Button
                variant="primary"
                type="button"
                className="d-block float-right px-4"
                onClick={finishStage}
                disabled={LoaderStatus.status && buttonToDisabled === 'finish'}
              >
                {LoaderStatus.status && buttonToDisabled === 'finish' ? 
                'Ejecutando...' : 
                'Confirmar'}
              </Button>
            </Col>
          </Row>
        </Grid>
      );
    }
  };

  return (
    <>
      <Modal
        size="xl"
        show={previewFileImage}
        onHide={() => setPreviewFileImage(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header className={"bg-complement-3"} closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Previsualización de { typeViewFile == true ? 'imagen' : 'PDF'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="w-100p ta-c">
            {typeViewFile == true ?
              <img 
                width="100%"
                src={previewFileImgUrl}
                alt='Creando previsualización de imagen...'
              />
            :
              <object
                data={previewFileImgUrl}
                type="application/pdf"
                width="100%"
                height="1000px"
              />
            }
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setPreviewFileImage(false)}
          >
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
      <ObservationModal
        show={observationModal}
        setShow={setObservationModal}
        el={activeElementValidable}
        stage={props.history.location.state.params.stage}
      />
      <Row>
        <Col xs={12}>
          <Row>
            <Col xs={12}>
              <h3 className="text-center mB-30 mT-40">{props.formTitle}</h3>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {dataForm.length > 0 ? (
                <Muuri
                  dataForm={dataForm}
                  createInputBaseTypeForm={createInputBaseTypeForm}
                />
              ) : (
                ""
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={12}> {buttonToolbar()}</Col>
          </Row>
        </Col>
      </Row>
      <Loader/>
    </>
  );
};

export default DynamicForm;
