import React from "react";
import { Form } from "react-bootstrap";
import Dropzone from "react-dropzone";
import "./file64.css";

const File64 = props => {
  const onDrop = acceptedFiles => {
    let file = acceptedFiles[0];
    props.setFile(file);
    // const reader = new FileReader();
    // reader.onload = event => {
    //   props.setFile(event.target.result);
    // };
    // reader.readAsDataURL(file);
  };

  return (
    <div className="margin-top-2em">
      <Form.Group controlId="formBasicFile">
        <Form.Label>
          <b>{props.label}</b>
        </Form.Label>
        <Dropzone onDrop={onDrop}>
          {({ getRootProps, getInputProps }) => (
            <div className="form-group input-container" {...getRootProps()}>
              <input
                style={{ display: "inline!important" }}
                className={props.error ? "form-control text-danger is-invalid" : "form-control"}
                {...getInputProps()}
                id={props.id}
                name={props.id}
              />
            </div>
          )}
        </Dropzone>
      </Form.Group>
    </div>
  );
};
export default File64;
