import axios from "axios";
import { NotificationManager } from "react-notifications";
import Cookies from "js-cookie";
import { DestroyCookies } from "../login/login_logic";

export class RequestNewVersion {
  constructor(url, method, headers, bodyRequest) {
    this.url = url;
    this.bodyRequest = bodyRequest;
    this.method = method;
    this.headers = headers;
  }

  async executeRequest() {
    let responseError = {
      code: 800,
      message:
        "Hubo un error al realizar la accion, intentelo mas tarde o contacte al administrador."
    };

    const authenticationToken = Cookies.get("authentication_token_03") ?
                                String(Cookies.get("authentication_token_03")) :
                                btoa(process.env.REACT_APP_TOKEN_WITHOUT_AUTHENTICATION);

    try {
      const response = await axios({
        method: this.method,
        url: this.url,
        data: this.bodyRequest,
        headers: {
          Authorization: authenticationToken
        }
      });

      if (response.data.code === 403 && response.data.data.clean) {
        DestroyCookies();
        window.location.reload();
      } 

      if(response.status === 200 && response.statusText === "OK"){

        return {
          code: response.data.code,
          response: response.data.data,
          responseComplete: response
        }
      }

    } catch (error) {

        responseError = {
          code: 500,
          message: "El servidor no responde"
        };

        NotificationManager.error(
          responseError.message,
          "Error " + responseError.code + ":"
        );
    }

    return responseError;
  }
}
