import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Modal,
  Image
} from "react-bootstrap";
import Dropzone from "react-dropzone";
import { uploadAvatar } from "./avatar_config_logic";
import OneColumn from "../layout/containers/OneColumn";
import Cookies from "js-cookie";
import DomainContext from "../domains/domainPathContext";
import { renderErrorsList } from "../edit_profile/edit_profile_logic";

const AvatarConfig = props => {
  const [fileImg, setFileImg] = useState("");
  const [previewFileImgUrl, setPreviewImgUrl] = useState("");
  const [previewFileImage, setPreviewFileImage] = useState(false);
  const [imageData, setImageData] = useState({ route: "", status: 0 });
  const subdomain = useContext(DomainContext);
  const layout = JSON.parse(Cookies.get('layout_03'));
  const [errors, setErrors] = useState({});

  let pageConfig = {
    active: "",
    title: ""
  };

  useEffect(() => {
    (async function() {
      const { avatar_03 } = Cookies.get();
      if (avatar_03 && avatar_03 !== "null") {
        setImageData({ route: avatar_03, status: 2 });
      } else {
        setImageData({
          route: `${process.env.PUBLIC_URL}/img/user_avatar.png`,
          status: 2
        });
      }
    })();
  }, []);

  const onDrop = acceptedFiles => {
    if (acceptedFiles.length > 0) {
      let file = acceptedFiles[0];
      const reader = new FileReader();
  
      setImageData({ route: URL.createObjectURL(file), status: 2 });
  
      reader.onload = event => {
        setFileImg(
          event.target.result.replace(/^data:image\/[a-z]+;base64,/, "")
        );
      };
      reader.readAsDataURL(file);
    }
    else setErrors({avatar:["Formato no válido, sólo se aceptan imagenes en formato .PNG"]});
  };

  const onSubmitFormHandler = async e => {
    e.preventDefault();
    await uploadAvatar(fileImg, subdomain);
  };

  const previewFileImgHandler = () => {
    setPreviewImgUrl(imageData.route);
    setPreviewFileImage(true);
  };

  return (
    <OneColumn
      {...props}
      pageConfig={pageConfig}
      hideTopMenu={true}
      fluid
      noTitle
    >
      <Container
        fluid={true}
        className="min-vh-100 bg-size-cover"
        style={{
          backgroundImage: `url(${layout.app_background_session})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'
        }}
      >
        <Container>
          <Row className="min-vh-100">
            <Col
              sm={12}
              md={{ span: 8, offset: 2 }}
              lg={{ span: 6, offset: 3 }}
              className="mY-30"
            >
              <Card className="mY-40@sm+">
                <Card.Header>
                  <Container>
                    <Row>
                      <Col className="pT-20 pB-10 pT-30@sm+ pB-20@sm+">
                        <h3 className="mB-20 text-center">
                          Subir imagen de avatar
                        </h3>
                      </Col>
                    </Row>
                  </Container>
                </Card.Header>
                <Card.Body>
                  <Form onSubmit={onSubmitFormHandler}>
                    <Form.Group controlId="formBasicFile">
                      <p className="text-center h6"><b>NOTA:</b> Sólo son válidas imágenes en formato PNG</p>
                      <Dropzone accept={['.png']} onDrop={onDrop}>
                        {({ getRootProps, getInputProps }) => (
                          <div
                            className="file-nilo-wrapper"
                            {...getRootProps()}
                          >
                            Selecciona un archivo
                            <input
                              style={{ display: "inline!important" }}
                              className="form-control"
                              {...getInputProps()}
                            />
                          </div>
                        )}
                      </Dropzone>
                    </Form.Group>

                    <div className="file-upload-wrapper">
                      <div
                        className={
                          "file-upload-actions " +
                          (imageData.status === 2 ? "d-block" : "d-none")
                        }
                      >
                        <i
                          className="fas fa-search-plus cursor-pointer"
                          onClick={() => previewFileImgHandler()}
                          title="preview image"
                        />
                        <i
                          className="fas fa-cloud-download-alt cursor-pointer"
                          onClick={() => {
                            window.open(`${imageData.route}`, "_blank");
                          }}
                          title="dowload file"
                        />
                      </div>
                      <img
                        className={
                          "loader " +
                          (imageData.status === 1 ? "d-block" : "d-none")
                        }
                        src={process.env.PUBLIC_URL + "/img/loader.gif"}
                        alt="Cargando ..."
                      />
                      <img
                        src={imageData.route}
                        alt="File"
                        className={
                          imageData.status === 2 ? "d-block" : "d-none"
                        }
                      />
                    </div>
                    {renderErrorsList(errors, "avatar")}

                    <Row className="btn-group-sign-up mt-4 pY-10">
                      <Col xs={12} sm={12}>
                        <Button
                          style={{ width: "45%" }}
                          variant="secondary"
                          onClick={() =>
                            props.history.push(`/${subdomain}/home`)
                          }
                        >
                          Cancelar
                        </Button>
                        <Button
                          style={{ width: "45%" }}
                          variant="primary"
                          type={ fileImg === "" ? "button" : "submit"}
                          className="d-block float-right"
                        >
                          Subir
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>

              <Modal
                size="lg"
                show={previewFileImage}
                onHide={() => setPreviewFileImage(false)}
                aria-labelledby="example-modal-sizes-title-lg"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="example-modal-sizes-title-lg">
                    Preview Image
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Image src={previewFileImgUrl} fluid />
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setPreviewFileImage(false)}
                  >
                    Cerrar
                  </Button>
                </Modal.Footer>
              </Modal>
            </Col>
          </Row>
        </Container>
      </Container>
    </OneColumn>
  );
};
export default AvatarConfig;
