import React from "react";

const LoaderPage = (props) => {

  return (
    <div style={{ marginTop:"40vh", display: "flex",justifyContent: "center",alignItems: "center" }}>
      <img
        src={process.env.PUBLIC_URL + "/loader_page.gif"}
        alt="errorcito"
        width="8%"
      />
    </div>
  );
};

export default LoaderPage;