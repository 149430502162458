import Cookies from "js-cookie";
import { NotificationManager } from "react-notifications";
import { RequestNewVersion } from "../classes/RequestNewVersion";
import { updateAllAlertNotifications } from "../alertNotificationTable/alert_notification_logic";

export const initForm = {
  email: "",
  password: ""
};

/**********************************************************FORGOT PASSWORD************************************************************************/
export const SignUp = async (form, props, subdomain, notificationsContext) => {
  let domainId = parseInt(Cookies.get('domain_id_03'));
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/auth/sign_in`,
    "post",
    null,
    {
      email: form.email,
      password: form.password,
      app_id: 3,
      domain_id: domainId,
    }
  );
  const isLogged = await request.executeRequest();

  if (isLogged.code !== 200) {

    NotificationManager.error( "Algo va mal inténtalo nuevamente" );
  }
  else {

    if ( isLogged.response.error ) {

      NotificationManager.error( isLogged.response.message );
    }
    else {

      await InitCookies(isLogged.responseComplete, props, subdomain);
      await updateAllAlertNotifications( isLogged.response.user.id );
    }
  }
};
/***********************************************************************************************************************************************/

export function DestroyCookies() {
  Cookies.remove("token_03", { path: "/" }); // removed!
  Cookies.remove("uid_03", { path: "/" });
  Cookies.remove("client_03", { path: "/" });
  Cookies.remove("userId_03", { path: "/" }); // removed!
  Cookies.remove("names_03", { path: "/" }); // removed!
  Cookies.remove("paternal_surname_03", { path: "/" });
  Cookies.remove("regime_03", { path: "/" }); // removed!
  Cookies.remove("auth_03", { path: "/" }); // removed!
  Cookies.remove("groups_03", { path: "/" });
  Cookies.remove("special_permissions_03", { path: "/" });
  Cookies.remove("avatar_03", { path: "/" });
  Cookies.remove("authentication_token_03", { path: "/" });

}

export const InitCookies = (response, props, subdomain) => {
  try {
    //HEADERS
    Cookies.set("token_03", response.headers["access-token"]);
    Cookies.set("uid_03", response.headers["uid"]);
    Cookies.set("client_03", response.headers["client"]);
    //USER
    Cookies.set("userId_03", response.data.data.user.id);
    Cookies.set("avatar_03", response.data.data.user.avatar);
    Cookies.set("names_03", response.data.data.user.names);
    Cookies.set(
      "paternal_surname_03",
      response.data.data.user.paternal_surname
    );
    Cookies.set("regime_03", response.data.data.user.regime);
    Cookies.set("groups_03", response.data.data.groups);
    Cookies.set(
      "special_permissions_03",
      response.data.data.user.special_permissions
    );
    Cookies.set("auth_03", true);
    Cookies.set("authentication_token_03", response.data.data.jwt);


    //SI TODO ESTA BIEN MANDAMOS AL HOME
    if (props.history) {
      props.history.push(`/${subdomain}/home`);
    }
  } catch (error) {
    //TALIBOLLO MOSTRARIA EL ERROR
    console.log(error);
  }
};
