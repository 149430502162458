import React from "react";
import { Request } from "../../classes/Request";
import { Modal, Button } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import Cookies from 'js-cookie';

/***********************************************************************************************************************************************/

export const privacyRequest = async ( setPrivacyModalElements, setShowModal ) => {
  const { domain_id_03 } = Cookies.get();
  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/terms/${domain_id_03}/privacy?token=${process.env.REACT_APP_TOKEN}`,
    "get",
    null,
    {}
  );
  const privacyRequestData = await request.executeRequest(false);
  if (privacyRequestData.code === 200) {

    setPrivacyModalElements(
      <>
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            {privacyRequestData.data.data.data.content.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{'maxHeight': 'calc(100vh - 210px)', 'overflowY': 'auto'}}>
          <div
            dangerouslySetInnerHTML={{__html: privacyRequestData.data.data.data.content.description}}
            style={{maxHeight: "60vh", overflowY: "scroll"}}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={()=>setShowModal(false)}>Aceptar</Button>
        </Modal.Footer>
     </>
    );
    setShowModal(true);
  }
  else {
    setShowModal(false);
    
    NotificationManager.warning(
      "No fue encontrado ningún aviso de privacidad"
    );
  }
};