/***********************************IMPORT LIBRARIES*************************************************** */
import React, { useState, useEffect } from "react";
import { Route, BrowserRouter, Switch, Redirect } from "react-router-dom";
import { LastLocationProvider } from "react-router-last-location";
import Cookies from "js-cookie";
import {NotificationContainer} from "react-notifications";

/*************************************ROUTE COMPONENTS****************************************** */
import Login from "./components/login/Login";
import NotFound from "./components/404/404";
import Home from "./components/home/Home";
import ForgotPassword from "./components/forgot_password/ForgotPassword";
import RecoveryPassword from "./components/forgot_password/RecoveryPassword";
import EditProfile from "./components/edit_profile/EditProfile";
import ChangePassword from "./components/change_password/ChangePassword";
import AlertNotificationTable from "./components/alertNotificationTable/AlertNotificationTable";
import AvatarConfig from "./components/avatar_config/AvatarConfig";
import ProcessList from "./components/processes_list/ProcessList";
import TableProcesses from "./components/table_process/TableProcesses";
import StartProcess from "./components/start_process/StartProcess";
import ErrorPage from "./components/helper_components/ErrorPage";
import StageDetail from "./components/static_form/StaticForm";
import LoaderContext from "./components/layout/shared/loader_context";
import ErrorCatcher from "./components/500/500";
import { domainRequest } from "./domainRequest";
import { getLayoutData } from "./app_login";
import CountProcessContext from "./components/layout/shared/count_process_context";
import UserManual from "./components/user_manual/UserManual";
import NotificationAlertListContext from "./components/alertNotificationTable/notification_alert_context";
import DomainPath from "./components/domains/domainPathContext";
import LoaderPage from "./components/load_page/LoaderPage";
import SuccessAutoassign from "./components/helper_components/SuccessAutoassign";

const App = props => {
  let subdomain = window.location.pathname.split("/");
  const { auth_03 } = Cookies.get();
  const [countAlerts, setCountAlerts] = useState(0);
  const [needUpdateCount, setNeedUpdateCount] = useState(false);
  const [statusLoader, setLoaderStatus] = useState(false);
  const [domainPath, setDomainPath] = useState(subdomain[1]);
  const [validDomain, setValidDomain] = useState(false);
  const [actionTo, setActionTo] = useState("Loader");
  const [countProcess, setCountProcess] = useState({});
  const updateCountHandler = (state, sumOrSubtractAlert, newCountOfAlerts) => {
    //THIS FUNCTION ALLOWS CHANGE THE STATE WHEN THE CHECKBOX MARK AS READ AND RENDER THE COMPONENT FOR THE COUNTER ALERT UPDATE
    if(sumOrSubtractAlert === "substractAlert"){
      setCountAlerts(countAlerts - 1);
    }
    else if( sumOrSubtractAlert === "sumAlert" ){
      setCountAlerts(newCountOfAlerts);
    } 
    setNeedUpdateCount(state);
  };

  useEffect(() => {

    (async function() {
      const domainName = Cookies.get("domain_name_03")
        ? Cookies.get("domain_name_03")
        : null;

      if (domainName) {
        if (domainName === subdomain[1]) {
          setActionTo("Normal");
        } else {
            const actionTo = await domainRequest(subdomain[1]);
            await getLayoutData();
            setActionTo(actionTo);
        }
      } else {
        const validDomain = await domainRequest(domainPath);
        if (!validDomain) {
          setValidDomain(false);
        } else {
          await getLayoutData();
          setValidDomain(true);
        }
      }
    })();
  }, [Cookies.get("domain_name_03") && Cookies.get("domain_name_03") !== subdomain[1] ? null : subdomain]);

  return (
    <ErrorCatcher>
      <BrowserRouter>
        <DomainPath.Provider value={domainPath}>
          <NotificationAlertListContext.Provider
            value={{
              count: countAlerts,
              updateCount: updateCountHandler,
              requireUpdate: needUpdateCount
            }}
          >
            <LoaderContext.Provider
              value={{
                status: statusLoader,
                show: status => {
                  setLoaderStatus(status);
                }
              }}
            >
            <NotificationContainer />
              <LastLocationProvider>
              <CountProcessContext.Provider value={{ countProcess: countProcess, setCountProcess: countProcess => { setCountProcess(countProcess); } }}>
                <Switch>
                  {/* /////////////////////////////////////////////////////////// RUTAS SIN CREDENCIALES  /////////////////////////////////////////////////////////////// */}
                  <Route
                    exact
                    path="/:subdomain"
                    render={props => {
                      if ( actionTo === "Normal" ) {
                        if (auth_03) {
                          if ( subdomain[1] !== Cookies.get("domain_name_03") ) {
                            setDomainPath(Cookies.get("domain_name_03"));
                            return (<Redirect to={`/${Cookies.get("domain_name_03")}/home`} />);
                          }
                          else {
                            return <Home {...props} />;
                          }
                        } else {
                          return <Login {...props} />;
                        }
                      } else if ( actionTo ===  "Loader" ) {
                        return <LoaderPage />;                      
                      }
                      else if ( actionTo ===  "NotFound" ) {
                        return <NotFound/>;
                      }
                    }}
                  />

                  <Route
                    path="/:subdomain/forgot_password"
                    render={props => {
                      if ( actionTo === "Normal" ) {
                        if (auth_03) {
                          return <Home {...props} />;
                        } else {
                          return <ForgotPassword {...props} />;
                        }
                      } else if ( actionTo === "Loader" ) {
                        return <LoaderPage />;
                      }
                      else {
                        return <NotFound/>;
                      }
                    }}
                  />
                  <Route
                    path="/:subpath/recover_password:reset_password_token?"
                    render={props => {
                      if ( actionTo ) {
                        if (auth_03) {
                          return <Home {...props} />;
                        } else {
                          return <RecoveryPassword {...props} />;
                        }
                      } else {
                        return <NotFound />;
                      }
                    }}
                  />
                  {/* /////////////////////////////////////////////////////////// RUTAS CON AUTENTICACION  /////////////////////////////////////////////////////////////// */}

                  <Route
                    exact
                    path="/:subdomain/home"
                    render={props => {
                      if ( actionTo === "Normal" ) {
                        if (auth_03) {
                          return <Home {...props} />;
                        } else {
                          setDomainPath(Cookies.get("domain_name_03"));
                          return (
                            <Redirect to={`/${Cookies.get("domain_name_03")}`} />
                          );
                        }
                      } else {
                        setDomainPath(Cookies.get("domain_name_03"));
                        return (
                          <Redirect
                            to={`/${Cookies.get("domain_name_03")}/home`}
                          />
                        );
                      }
                    }}
                  />
                  <Route
                    path="/:subdomain/edit_profile"
                    render={props => {
                      if ( actionTo === "Normal" ) {
                        if (auth_03) {
                          return <EditProfile {...props} />;
                        } else {
                          setDomainPath(Cookies.get("domain_name_03"));
                          return (
                            <Redirect to={`/${Cookies.get("domain_name_03")}`} />
                          );
                        }
                      } else {
                        setDomainPath(Cookies.get("domain_name_03"));
                        return (
                          <Redirect
                            to={`/${Cookies.get("domain_name_03")}/edit_profile`}
                          />
                        );
                      }
                    }}
                  />
                  <Route
                    path="/:subdomain/process_list"
                    render={props => {
                      if ( actionTo === "Normal" ) {
                        if (auth_03) {
                          return <ProcessList {...props} />;
                        } else {
                          setDomainPath(Cookies.get("domain_name_03"));
                          return (
                            <Redirect to={`/${Cookies.get("domain_name_03")}`} />
                          );
                        }
                      } else {
                        setDomainPath(Cookies.get("domain_name_03"));
                        return (
                          <Redirect
                            to={`/${Cookies.get("domain_name_03")}/process_list`}
                          />
                        );
                      }
                    }}
                  />
                  <Route
                    path="/:subdomain/table_process/:status"
                    render={props => {
                      if ( actionTo === "Normal" ) {
                        if (auth_03) {
                          return <TableProcesses {...props} />;
                        } else {
                          setDomainPath(Cookies.get("domain_name_03"));
                          return (
                            <Redirect to={`/${Cookies.get("domain_name_03")}`} />
                          );
                        }
                      } else {
                        setDomainPath(Cookies.get("domain_name_03"));
                        return (
                          <Redirect
                            to={`/${Cookies.get(
                              "domain_name_03"
                            )}/${subdomain[2]}/${subdomain[3]}`}
                          />
                        );
                      }
                    }}
                  />
                  <Route
                    path="/:subdomain/alert_notifications"
                    render={props => {
                      if ( actionTo === "Normal" ) {
                        if (auth_03) {
                          return <AlertNotificationTable {...props} />;
                        } else {
                          setDomainPath(Cookies.get("domain_name_03"));
                          return (
                            <Redirect to={`/${Cookies.get("domain_name_03")}`} />
                          );
                        }
                      } else {
                        setDomainPath(Cookies.get("domain_name_03"));
                        return (
                          <Redirect
                            to={`/${Cookies.get(
                              "domain_name_03"
                            )}/alert_notifications`}
                          />
                        );
                      }
                    }}
                  />
                  <Route
                    path="/:subdomain/error_page"
                    render={props => {
                      if ( actionTo === "Normal" ) {
                        if (auth_03) {
                          return <ErrorPage {...props} />;
                        } else {
                          setDomainPath(Cookies.get("domain_name_03"));
                          return (
                            <Redirect to={`/${Cookies.get("domain_name_03")}`} />
                          );
                        }
                      } else {
                        setDomainPath(Cookies.get("domain_name_03"));
                        return (
                          <Redirect
                            to={`/${Cookies.get("domain_name_03")}/error_page`}
                          />
                        );
                      }
                    }}
                  />
                  <Route
                    path="/:subdomain/success_autoassign"
                    render={props => {
                      if ( actionTo === "Normal" ) {
                        if (auth_03) {
                          return <SuccessAutoassign {...props} />;
                        } else {
                          setDomainPath(Cookies.get("domain_name_03"));
                          return (
                            <Redirect to={`/${Cookies.get("domain_name_03")}`} />
                          );
                        }
                      } else {
                        setDomainPath(Cookies.get("domain_name_03"));
                        return (
                          <Redirect
                            to={`/${Cookies.get("domain_name_03")}/success_autoassign`}
                          />
                        );
                      }
                    }}
                  />
                  <Route
                    path="/:subdomain/start_process"
                    render={props => {
                      if ( actionTo === "Normal" ) {
                        if (auth_03) {
                          return <StartProcess {...props} />;
                        } else {
                          setDomainPath(Cookies.get("domain_name_03"));
                          return (
                            <Redirect to={`/${Cookies.get("domain_name_03")}/`} />
                          );
                        }
                      } else {
                        setDomainPath(Cookies.get("domain_name_03"));
                        return (
                          <Redirect
                            to={`/${Cookies.get("domain_name_03")}/process_list`}
                          />
                        );
                      }
                    }}
                  />
                  <Route
                    path="/:subdomain/change_password"
                    render={props => {
                      if ( actionTo === "Normal" ) {
                        if (auth_03) {
                          return <ChangePassword {...props} />;
                        } else {
                          setDomainPath(Cookies.get("domain_name_03"));
                          return (
                            <Redirect to={`/${Cookies.get("domain_name_03")}`} />
                          );
                        }
                      } else {
                        setDomainPath(Cookies.get("domain_name_03"));
                        return (
                          <Redirect
                            to={`/${Cookies.get(
                              "domain_name_03"
                            )}/change_password`}
                          />
                        );
                      }
                    }}
                  />
                  <Route
                    path="/:subdomain/stage_detail:task?"
                    render={props => {
                      if ( actionTo === "Normal" ) {
                        if (auth_03) {
                          return <StageDetail {...props} />;
                        } else {
                          setDomainPath(Cookies.get("domain_name_03"));
                          return (
                            <Redirect to={`/${Cookies.get("domain_name_03")}`} />
                          );
                        }
                      } else {
                        setDomainPath(Cookies.get("domain_name_03"));
                        return (
                          <Redirect
                            to={`/${Cookies.get(
                              "domain_name_03"
                            )}/table_process/assign`}
                          />
                        );
                      }
                    }}
                  />
                  <Route
                    path="/:subdomain/avatar_config"
                    render={props => {
                      if ( actionTo === "Normal" ) {
                        if (auth_03) {
                          return <AvatarConfig {...props} />;
                        } else {
                          setDomainPath(Cookies.get("domain_name_03"));
                          return (
                            <Redirect to={`/${Cookies.get("domain_name_03")}`} />
                          );
                        }
                      } else {
                        setDomainPath(Cookies.get("domain_name_03"));
                        return (
                          <Redirect
                            to={`/${Cookies.get("domain_name_03")}/avatar_config`}
                          />
                        );
                      }
                    }}
                  />
                  <Route
                    path="/:subdomain/user_manual"
                    render={props => {
                      if (validDomain) {
                        if (auth_03) {
                          return <UserManual history={props.history} />
                        } else {
                          setDomainPath(Cookies.get("domain_name_03"));
                          setValidDomain(true);
                          return (
                            <Redirect to={`/${Cookies.get("domain_name_03")}`} />
                          );
                        }
                      } else {
                        setDomainPath(Cookies.get("domain_name_03"));
                        setValidDomain(true);
                        return (
                          <Redirect
                            to={`/${Cookies.get("domain_name_03")}/user_manual`}
                          />
                        );
                      }
                    }}
                  />
                  {/* /////////////////////////////////////////////////////////// RUTAS CON AUTENTICACION  /////////////////////////////////////////////////////////////// */}
                  <Route component={NotFound} />
                </Switch>
              </CountProcessContext.Provider>
              </LastLocationProvider>
            </LoaderContext.Provider>
          </NotificationAlertListContext.Provider>
        </DomainPath.Provider>
      </BrowserRouter>
    </ErrorCatcher>
  );
};

export default App;
