import React, { useState, useContext } from "react";
import { Button, Card, Container, Form, Col, Row } from "react-bootstrap";
import queryString from "query-string";
import { initForm, UpdatePasswordRecovery } from "./recovery_password_logic";
import NotFound from "../404/404";
import Plain from "../layout/containers/Plain";
import DomainContext from "../domains/domainPathContext";
import Cookies from "js-cookie";

const RecoveryPassword = props => {
  const [form, setForm] = useState(initForm);
  const getParams = queryString.parse(props.location.search);
  const subdomain = useContext(DomainContext);
  const layout = JSON.parse(Cookies.get('layout_03'))

  const onChangeHandler = event => {
    setForm({
      ...form,
      [event.target.name]: event.target.value
    });
  };

  const onSubmitFormHandler = async e => {
    e.preventDefault();
    await UpdatePasswordRecovery(form, getParams.reset_password_token, props, subdomain);
  };

  if (typeof getParams.reset_password_token !== "undefined") {
    return (
      <Plain {...props} noHeader={true} noFooter={true}>
        <Container
          fluid={true}
          className="min-height-100vh bg-size-cover"
          style={{
            backgroundImage: `url(${layout.app_background_undefined_session})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center'
          }}
        >
          <Container>
            <Row style={{ minHeight: "100vh" }}>
              <Col
                sm={12}
                md={{ span: 10, offset: 1 }}
                lg={{ span: 6, offset: 3 }}
                className="mY-40"
              >
                <Card className="mY-40@sm+">
                  <Card.Header>
                    <Container>
                      <Row>
                        <Col
                          xs={12}
                          md={{ span: 10, offset: 1 }}
                          className="pT-20 pB-10 pT-30@sm+ pB-20@sm+"
                        >
                          <h3 className="mb-0 text-center">
                            Olvidaste tu contraseña
                          </h3>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Header>
                  <Card.Body>
                    <Container>
                      <Row>
                        <Col
                          xs={12}
                          md={{ span: 10, offset: 1 }}
                          className="pT-30"
                        >
                          <Form onSubmit={onSubmitFormHandler}>
                            <Form.Group controlId="formBasicNewPassword">
                              <Form.Label className="font-weight-bold text-left w-100">
                                Nueva Contraseña:
                              </Form.Label>
                              <Form.Control
                                type="password"
                                placeholder="Ingresa tu nueva contraseña"
                                required
                                name="newPassword"
                                onChange={onChangeHandler}
                                value={form.newPassword}
                                minLength="8"
                              />
                              <Form.Text className="text-muted w-100 text-left">
                                Ingresa la nueva contraseña.
                              </Form.Text>
                            </Form.Group>

                            <Form.Group controlId="formBasicConfirmNewPassword">
                              <Form.Label className="font-weight-bold text-left w-100">
                                Confirmación de contraseña:
                              </Form.Label>
                              <Form.Control
                                type="password"
                                placeholder="Confirma la contraseña anterior"
                                required
                                name="confirmNewPassword"
                                onChange={onChangeHandler}
                                value={form.confirmNewPassword}
                                minLength="8"
                              />
                              <Form.Text className="text-muted  w-100 text-left">
                                Confirma la contraseña ingresada anteriormente
                              </Form.Text>
                            </Form.Group>

                            <Row className="btn-group-sign-up pY-20">
                              <Col
                                xs={12}
                                sm={12}
                                className="dinamic-form-tool-bar"
                              >
                                <Button
                                  variant="secondary"
                                  className="float-left"
                                  onClick={() =>
                                    props.history.push(`/${subdomain}/`)
                                  }
                                >
                                  Cancelar
                                </Button>
                                <Button
                                  variant="primary"
                                  type="submit"
                                  className="float-right"
                                >
                                  Aplicar
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </Container>
      </Plain>
    );
  } else {
    return <NotFound />;
  }
};

export default RecoveryPassword;
