import Cookies from "js-cookie";
import tawkTo from 'tawkto-react';

export const hideChat = () => {
  if (window.Tawk_API) {
    if (window.Tawk_API.hasOwnProperty('hideWidget')) {
      window.Tawk_API.hideWidget();
    }
  }
};

export const showChat = () => {
  const Tawk_API = window.Tawk_API || {};
  const { auth_03 } = Cookies.get();

  if (!Tawk_API.hasOwnProperty('showWidget')) {
    Tawk_API.onLoad = function () {
      if (!auth_03) {
        Tawk_API.hideWidget();
      } else {
        Tawk_API.showWidget();
      }
    };

    const tawkToKey = process.env.REACT_APP_TAWK_KEY;
    const tawkToPropertyId = process.env.REACT_APP_TAWK_ID;
    tawkTo(tawkToPropertyId, tawkToKey)
  } else {
    if (auth_03) {
      if (window.Tawk_API.hasOwnProperty('showWidget')) {
        window.Tawk_API.showWidget();
      }
    }
  }
};
