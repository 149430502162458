import React, { useState } from "react";
import { Grid, Col, Row } from "react-flexbox-grid";
import { Image, Modal } from "react-bootstrap";
import Cookies from 'js-cookie';
import { privacyRequest } from "./footer_logic";

const Footer = props => {
  const layout = JSON.parse(Cookies.get('layout_03'));
  const [privacyModalElements, setPrivacyModalElements] = useState({});
  const [showModal, setShowModal] = useState(false);

  const getDataPrivacy = async () => {
    await privacyRequest( setPrivacyModalElements, setShowModal );
  };

  return (
    <footer>
      <Grid fluid={true}>
        <Grid className="pY-40">
          <Row>
            <Col xs={12}>
              <Row center="xs" middle="xs" className="pB-20">
                <Col xs={4} sm={4}>
                  <Row between="xs">
                    <Col xs={12}>
                      {layout.app_footer_logo_url_image !== null &&
                        <Image
                          style={{ width: "100px" }}
                          rounded
                          src={layout.app_footer_logo_url_image}
                        />
                      }
                    </Col>
                  </Row>
                </Col>
                <Col xs={4} sm={4}>
                  <span>
                    {layout.app_street}
                    {layout.app_number !== null && layout.app_number !== ""
                      ? ` #${layout.app_number},`
                      : layout.app_number}
                    {layout.app_int_number !== null && layout.app_int_number !== ""
                      ? ` ${layout.app_int_number},`
                      : layout.app_int_number}
                    {layout.app_colony !== null && layout.app_colony !== ""
                      ? ` Col. ${layout.app_colony},`
                      : layout.app_colony}{" "}
                    {layout.app_city !== null && layout.app_city !== ""
                      ? ` ${layout.app_city},`
                      : layout.app_city}
                    {layout.app_state !== null && layout.app_state !== ""
                      ? ` ${layout.app_state}.`
                      : layout.app_state}
                    <br />
                    <span>
                      {layout.app_phone !== null && layout.app_phone !== ""
                        ? `Tel: ${layout.app_phone}`
                        : layout.app_phone}
                    </span>
                  </span>
                </Col>
                <Col xs={4} sm={4}>
                  <Row between="xs">
                    <Col xs={12}>
                      {layout.app_facebook_url_image !== null &&
                        <a
                          href={layout.app_facebook_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Image
                            style={{ width: "40px", marginLeft: "20px" }}
                            rounded
                            src={layout.app_facebook_url_image}
                          />
                        </a>
                      }
                      {layout.app_twitter_url_image !== null &&
                        <a
                          href={layout.app_twitter_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Image
                            style={{ width: "40px", marginLeft: "20px" }}
                            rounded
                            src={layout.app_twitter_url_image}
                          />
                        </a>
                      }
                      {layout.app_youtube_url_image !== null &&
                       <a
                          href={layout.app_youtube_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Image
                            style={{ width: "40px", marginLeft: "20px" }}
                            rounded
                            src={layout.app_youtube_url_image}
                          />
                        </a>
                      }
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row center="xs" middle="xs">
                <Col xs={12}>
                  <span>
                    Consulta nuestro{" "}
                    <span 
                      onClick={getDataPrivacy}
                      className="cursor-pointer text-primary px-1"
                    >
                       aviso de privacidad
                    </span>{" "}
                    vigente
                  </span>
                </Col>
              </Row>

              {/*<Row center="xs" middle="xs">
                <Col xs={12}>
                  <span>
                    Copyright © 2018 Develop by{" "}
                    <a
                      href="https://agilgob.com/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Agilgob
                    </a>
                    . All rights reserved.
                  </span>
                </Col>
              </Row>*/}
            </Col>
          </Row>
        </Grid>
      </Grid>
      <>
        <Modal
          size="lg"
          show={showModal}
          onHide={() => setShowModal(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          {privacyModalElements}
        </Modal>
      </>
    </footer>
  );
};

export default Footer;
