import { Request } from "../classes/Request";
import Cookies from "js-cookie";
////////////////////////////////////////////////////FUNCION QUE TRAE EL MANUAL DE USUARIO/////////////////////////////////////////////////////////////////

export const getUserManualByGroupsId = async ( setFirstText, setSecondText, setHowManyManuals, setDataButtons ) => {
    const {userId_03} = Cookies.get(); 
    const request = new Request(
      `${process.env.REACT_APP_URL_API_LARAVEL}/groups/manuals/${userId_03}?token=${process.env.REACT_APP_TOKEN}`,
      "get",
      null,
      {}
    );
    const responseManuals = await request.executeRequest();

    if ( responseManuals.code === 200 ) {
        if ( responseManuals.data.data.data.manuals &&
             responseManuals.data.data.data.manuals.length > 1 
           ) {
            setFirstText("los manuales de usuario que describen");
            setSecondText("visualizarlos haz clic en los siguientes enlaces");
            setHowManyManuals("Manuales");
            setDataButtons(responseManuals.data.data.data.manuals);
        }
        else if ( 
                    responseManuals.data.data.data.manuals &&
                    responseManuals.data.data.data.manuals.length < 2 
                ) {
            setDataButtons(responseManuals.data.data.data.manuals); 
        }
    }
  };


  export const xmlHttpRequestManualDownload = (url, success) => {
    const { authentication_token_03 } = Cookies.get();
    let xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.setRequestHeader('Authorization', authentication_token_03);
    xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    xhr.responseType = 'blob';
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if (success) {
          success(xhr.response);

        }
      }
    };
    xhr.send();
  };