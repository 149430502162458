import React from "react";
import Cookies from "js-cookie";
import { DestroyCookies } from "../../login/login_logic";
import { Badge } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { RequestNewVersion } from "../../classes/RequestNewVersion";
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////FUNCION QUE TRAE LOS DATOS DE LAS TABLAS/////////////////////////////////////////////////////////////////

export const logOut = async (props, subdomain) => {
  const { token_03, uid_03, client_03 } = Cookies.get();

  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/auth/sign_out`,
    "delete",
    null,
    {
      access_token_name: token_03,
      uid_name: uid_03,
      client_name: client_03
    }
  );

  const responseLogOutData = await request.executeRequest();

  if (responseLogOutData.code === 200) {
    //MOSTRAR ALERTA EXITOSA CON EL MENSAJE QUE RETORNA LA API COMPONENTE DESPUES DE DESLOGUEARSE
    if (
      !responseLogOutData.response.error &&
      typeof responseLogOutData.response.message === "string" &&
      responseLogOutData.response.message !== ""
    ) {

      setTimeout(() => {
        NotificationManager.success(responseLogOutData.response.message);
      }, 1000);

      await DestroyCookies();
    }
  }
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function capital_letter(str) {
  str = str.trim();
  str = str.split(" ");

  for (var i = 0, x = str.length; i < x; i++) {
    str[i] = str[i][0].toUpperCase() + str[i].substr(1);
  }

  return str.join(" ");
}

export const setImgAvatar = (avatarImg, countAlerts) => {
  const { auth_03, names_03, paternal_surname_03 } = Cookies.get();

  if (auth_03) {
    return (
      <div className="pull-left">
        <Badge
          className="pull-right mL-10"
          pill
          variant="danger"
          style={ countAlerts === 0 ? 
            {
              display: "none",
              float: "left"
            } : 
            {
              display: "inline-block",
              float: "left"
            }
          }
        >
          {countAlerts !== 0 ? countAlerts : 0}
        </Badge>{" "}
        <span style={{display: "inline-block", float: "right"}}>
          {names_03} {paternal_surname_03}
        </span>
      </div>
    );
  } else {
      return "";
  }
};
