import { NotificationManager } from "react-notifications";
import Cookies from "js-cookie";
import { Request } from "../../classes/Request";
import { RequestNewVersion } from "../../classes/RequestNewVersion";
import { executePKCS7 } from "./create_pkcs7";

export const decryptCerAndKey = async (
  key, cer, password, 
  dataField, stage_id, setLocalLoader,
  setShowDowloadDocument, showModalSignature,
  setFinalDocumentUrlPDF, setSignInStep
) => {

  const resultKey = await decryptKey(key, password);
  const resultCer = await decryptCer(cer);
  
  const { extra_attributes } = dataField;
  const field_id = dataField['id'];
  const user_id = Cookies.get('userId_03');
  const { document_information } = extra_attributes;

  if (!resultKey['error'] && !resultCer['error']) {

    const pksc7Result = await executePKCS7(
      resultKey['pemKey'],
      resultCer['pemCer'],
      extra_attributes,
      password
    );

    const request = new RequestNewVersion(
      `${process.env.REACT_APP_URL_API_LARAVEL}/signature/service/sign`,
      "post",
      null,
      {
        stage_id: stage_id,
        field_id: field_id,
        user_id: user_id,
        pkcs7: pksc7Result,
        content: document_information['data']
      }
    );
    const responseSign = await request.executeRequest();

    if (
      responseSign.code === 200 && 
      responseSign.response &&
      responseSign.response.value && 
      typeof responseSign.response.value === 'object'
    ) {

      setFinalDocumentUrlPDF(responseSign.response.value.value.url);
      setLocalLoader(false);
      setShowDowloadDocument(true);
      showModalSignature(false);
      setSignInStep(true);

      NotificationManager.success(
        'La petición para el firmado ha sido exitosa',
        '',
        5000
      );

      return responseSign.response.value.value;
    }
    else {
      const { code, error, message } = responseSign && responseSign.response ? 
                                       responseSign.response : { 
                                         code: 500,
                                         error: true,
                                         message: 'Ha ocurrido un error desconocido durante el firmado del documento...'
                                        };
      if (code && error && message) {
        NotificationManager.error(
          message,
          'Error ' + code + ':',
          4500
        );
      }
      else {
        NotificationManager.error('Ha ocurrido un error desconocido durante el firmado del documento...');
      }
      
      setLocalLoader(false);
      return null;
    }

    return null;
  }
  setLocalLoader(false);
  return null;
};

const decryptKey = async (key, password) => {

  return new Promise( (resolve, reject) => {
    let error = {
      error: true,
      message: ''
    }

    if (window.File && window.FileList && window.FileReader && window.Blob) {
      var encryptedKeyPemFormat = '';
      var rsakey1;
      var pem;
      var file = key;

      // If no key file is selected returns error.
      if (file === undefined) {
        error['message'] = 'Por favor seleccione su llave privada';
          NotificationManager.error(error['message']);
          resolve(error);
      };

      if (password <= 0) {
        error['message'] = 'Por favor escriba su contraseña antes de continuar.';
        NotificationManager.error(error['message']);
        resolve(error);
      };

      var reader = new FileReader();
      reader.onload = function(e) {
          try {
            resolve({
              error: false,
              pemKey: e.target.result
            });
          } catch(err){
            error['message'] = err;
            // Error with password or damaged key.
            NotificationManager.error(
              err ? err : 'Uno o varios campos presentan un error. Por favor intentarlo de nuevo.',
              err ? 'Error: ' : '',
              3500
            );
            resolve(error);
          };
        };
        reader.readAsArrayBuffer(file);
    } else {
      error['message'] = 'Tu navegador no soporta esta función. Puedes usar Internet Explorer 10, Google Chrome, Mozilla Firefox o Safari.';
      NotificationManager.error(error['message']);
      resolve(error);
    }
  });
};

const decryptCer = async (cer) => {

  return new Promise( (resolve, reject) => {
    let error = {
      error: true,
      message: ''
    }
    
    if (window.File && window.FileList && window.FileReader && window.Blob) {
      var fileCer = cer;

      // If no key file is selected returns error.
      if (fileCer === undefined) {
        error['message'] = 'Por favor seleccione el archivo .cer';
          NotificationManager.error(error['message']);
          resolve(error);
      };

      var reader = new FileReader();
      reader.onload = function(e){
        resolve({
          error: false,
          pemCer: e.target.result
        });
      };
      
      reader.readAsArrayBuffer(fileCer);
    } else {
      error['message'] = 'Tu navegador no soporta esta función. Puedes usar Internet Explorer 10, Google Chrome, Mozilla Firefox o Safari.';
      NotificationManager.error(error['message']);
      resolve(error);
    }
  });
};


export const validateCer = (cer) => {
  if (cer) {
    var f = cer;

    var invalid = true;
    var arr = f.name.split('.');
    // no extension or hidden file
    if (arr.length === 1 || (arr[0] === '' &&  arr.length === 2)) {
        invalid = true;
    } else {
        var ext = arr.pop();
        if (ext.indexOf('cer') == -1){
            invalid = true;
        } else{
            invalid = false;
        }
    };
  
    if (invalid) {
        NotificationManager.error('Archivo incorrecto. Para el certificado, selecciona un archivo que termine en .cer');
        return false;
    } else {
        NotificationManager.success('¡Archivo .cer correcto!');
        return true;
    }
  }
};

export const validateKey = (key) => {
  if (key) {
    var f = key;

    var invalid = true;
    var arr = f.name.split('.');
    // no extension or hidden file
    if (arr.length === 1 || (arr[0] === '' &&  arr.length === 2)) {
        //invalid = true;
    } else {
        var ext = arr.pop();
        if (ext.indexOf('key') == -1) {
            //invalid = true;
        } else {
            invalid = false
        }
    };
  
    if (invalid) {
  
        NotificationManager.error('Archivo incorrecto. Para la llave privada, selecciona un archivo que termine en .key ')
        return false;
    } else {
        NotificationManager.success('¡Archivo .key correcto!');
        return true;
    }
  }
};

export const initValues = (props, setSignedData, setSignature) => {
  //CARGA VALORES INICIALES
  checkDefaultValues(props, setSignedData, setSignature);

  //INICIALIZAMOS LOS VALORES DE LA FIRMA
  initSettingsSignature(props);
};

export const sendSignatureServer = async (
  tabFirel,
  cer,
  key,
  password,
  firelPfx,
  passwordFirel,
  props,
  setSignature,
  signedData,
  setFinalDocumentUrlPDF,
  setLocalLoader,
  setShowDowloadDocument,
  showModalSignature,
  setSignInStep
) => {
  //EVENTO CUANDO INTENTAN MANDAR LA INFORMACION POR  FIRMA FIEL
  if (!tabFirel) {
    if (cer !== null && key !== null && password !== "") {
      //VALIDA Y MANDA A LLAMAR LAS FUNCIONES DE LLAVES Y PKCS1
      window.firma.setEvidences(0);
      const referStringBase64 = utf8_to_b64(
        `${props.stage}|${props.el.name}|${Cookies.get("userId_03")}|${
          props.el.id
        }|${props.el.extra_attributes.document_id}`
      );
      window.firma.setReferencia(referStringBase64);
      validaLlavesCerKey(
        props,
        password,
        signedData,
        setSignature,
        setFinalDocumentUrlPDF,
        setLocalLoader,
        setShowDowloadDocument,
        showModalSignature,
        setSignInStep
      );
    } else if (cer === null && key === null) {
      setLocalLoader(false);
      NotificationManager.error(
        "Se necesitan los archivos .cer y .key para generar una firma digital."
      );
    } else if (password === "") {
      setLocalLoader(false);
      NotificationManager.error("Se necesita la llave para firmar documentos.");
    }
  } else {
    //EVENTO CUANDO INTENTAN MANDAR LA INFORMACION POR  FIRMA FIREL
    if (firelPfx !== null && passwordFirel !== "") {
      //VALIDA Y MANDA A LLAMAR LAS FUNCIONES DE VALIDAFPX Y PKCS1
      validateFPX(
        props,
        passwordFirel,
        signedData,
        setSignature,
        setFinalDocumentUrlPDF,
        setLocalLoader,
        setShowDowloadDocument,
        showModalSignature,
        setSignInStep
      );
    } else if (firelPfx === null) {
      setLocalLoader(false);

      NotificationManager.error(
        "Se necesita el archivo .fpx para  generar una firma digital."
      );
    } else if (passwordFirel === "") {
      setLocalLoader(false);

      NotificationManager.error(
        "Se necesita la contraseña para generar la firma."
      );
    }
  }
};

/***************************************VALIDATE KEYS**************************************** */
function validaLlavesCerKey(
  props,
  password,
  signedData,
  setSignature,
  setFinalDocumentUrlPDF,
  setLocalLoader,
  setShowDowloadDocument,
  showModalSignature,
  setSignInStep
) {
  //VALIDA QUE EL CER Y KEY SEAN LEGITIMOS Y MANDA LLAMAR EL FIRMADO.
  try {
    window.firma.validateKeyPairs(password, function(objResult) {
      if (objResult.state === 0) {
        window.firma.setEvidences(0);
        const referStringBase64 = utf8_to_b64(
          `${props.stage}|${props.el.name}|${Cookies.get("userId_03")}|${
            props.el.id
          }|${props.el.extra_attributes.document_id}`
        );
        window.firma.setReferencia(referStringBase64);
        if (props.isDocument) {
          //SI ESTA FIRMANDO EL DOCUMENTO EJECUTARA ESTE METODO
          const base64String = utf8_to_b64(props.htmlIntoBase64);
          const encodedString = new Buffer(props.htmlIntoBase64).toString(
            "base64"
          );
          const file = convertStringIntoPdf(encodedString);
          signBothPKCS(
            file,
            props.htmlIntoBase64,
            base64String,
            setFinalDocumentUrlPDF,
            setLocalLoader,
            setShowDowloadDocument,
            showModalSignature,
            setSignInStep
          );
          //pkcs1AndDigest(base64String, file, props.htmlIntoBase64);
          //signPkCS7(file, props.htmlIntoBase64);
        } else {
          signPKCS1(signedData, setSignature);
        }
      } else {
        setLocalLoader(false);

        NotificationManager.error(objResult.description);
      }
    });
  } catch (error) {
    setLocalLoader(false);
  }
}

function validateFPX(
  props,
  passwordFirel,
  signedData,
  setSignature,
  setFinalDocumentUrlPDF,
  setLocalLoader,
  setShowDowloadDocument,
  showModalSignature,
  setSignInStep
) {
  try {
    window.firma.openPfx(passwordFirel, function(objResult) {
      if (objResult.state === 0) {
        window.firma.setEvidences(0);
        const referStringBase64 = utf8_to_b64(
          `${props.stage}|${props.el.name}|${Cookies.get("userId_03")}|${
            props.el.id
          }|${props.el.extra_attributes.document_id}`
        );
        window.firma.setReferencia(referStringBase64);
        if (props.isDocument) {
          //SI ESTA FIRMANDO EL DOCUMENTO EJECUTARA ESTE METODO
          const base64String = utf8_to_b64(props.htmlIntoBase64);
          const encodedString = new Buffer(props.htmlIntoBase64).toString(
            "base64"
          );
          const file = convertStringIntoPdf(encodedString);
          signBothPKCS(
            file,
            props.htmlIntoBase64,
            base64String,
            setFinalDocumentUrlPDF,
            setLocalLoader,
            setShowDowloadDocument,
            showModalSignature,
            setSignInStep
          );
          //pkcs1AndDigest(base64String, file, props.htmlIntoBase64);
          //signPkCS7(file, props.htmlIntoBase64);
        } else {
          signPKCS1(signedData, setSignature);
        }
      } else {
        setLocalLoader(false);
        NotificationManager.error(objResult.description);
      }
    });
  } catch (error) {
    setLocalLoader(false);
  }
}
/******************************************************************************************** */

function convertStringIntoPdf(encodedString) {
  //FUNCION QUE CONVIERTE EL HTML EN UN FILE PDF
  const byteCharacters = atob(encodedString);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  let blob = new Blob([byteArray], { type: "application/pdf" });
  let file = new File([blob], "signature.pdf");
  return file;
}

async function signPkCS7(
  file,
  html,
  setFinalDocumentUrlPDF,
  setLocalLoader,
  setShowDowloadDocument,
  showModalSignature,
  setSignInStep
) {
  window.firma.signPKCS7(
    file,
    1024,
    window.fielnet.Digest.SHA1,
    {
      tsa: {
        name: "tsaName",
        algorithm: window.fielnet.Digest.SHA1
      },
      nom: {
        name: "noName"
      }
    },
    function(objResult) {
      if (objResult.state === 0) {
        finishFirmDocument(
          html,
          objResult.signDocument,
          objResult.signVector,
          setFinalDocumentUrlPDF,
          setLocalLoader,
          setShowDowloadDocument,
          showModalSignature,
          setSignInStep
        );
      } else {
        setLocalLoader(false);
      }
    }
  );
}

/***********************************INIT FUNCTIONS******************************************* */
function checkDefaultValues(props, setSignedData, setSignature) {
  //FUNCION QUE SETEA LOS VALORES EN LOS CAMPOS CUANDO YA TIENEN DATOS
  if (props.isDocument) {
  } else {
    if (props.el.value !== "") {
      setSignature(props.el.value);
    }
    props.el.extra_attributes.form.inputs.forEach(el => {
      if (el.name === "signed_data") {
        setSignedData(el.value);
      }
    });
  }
}

function initSettingsSignature(props) {
  //VALIDA E INICIALIZA LA FUNCIONALIDAD DE LA FIRMA..
  if (window.firma.validateWebBrowser()) {
    //INIT READ INPUT FILES
    window.firma.readCertificateAndPrivateKey(
      `${props.el.name}_cerFiel`,
      `${props.el.name}_keyFiel`
    );
    window.firma.readPfx(`${props.el.name}_pfxFirel`);
  } else {
    NotificationManager.error(
      "El navegador no es compatible para la funcionalidad de firma."
    );
  }
}
/*******************************************************************************************/

function signPKCS1(signedData, setSignature = null) {
  //FUNCION QUE HACE EL FIRMADO PKCS1, PARAMS TEXTO A FIRMAR Y FUNCION QUE SETEA FIRMA EN CAMPO
  window.firma.signPKCS1(
    signedData,
    window.fielnet.Digest.SHA1,
    window.fielnet.Encoding.UTF8,
    function(res) {
      if (res.state === "0") {
        setSignature(res.sign);
        return res;
      } else {
        NotificationManager.error(
          "Ocurrio un error a la hora de realizar el firmado"
        );
      }
    }
  );
}

function signBothPKCS(
  file,
  html,
  htmlBase64,
  setFinalDocumentUrlPDF,
  setLocalLoader,
  setShowDowloadDocument,
  showModalSignature,
  setSignInStep
) {
  window.firma.signPKCS1(
    htmlBase64,
    window.fielnet.Digest.SHA1,
    window.fielnet.Encoding.UTF8,
    function(res) {
      if (res.state === "0") {
        signPkCS7(
          file,
          html,
          setFinalDocumentUrlPDF,
          setLocalLoader,
          setShowDowloadDocument,
          showModalSignature,
          setSignInStep
        );
      } else {
        setLocalLoader(false);

        NotificationManager.error(
          "Ocurrio un error a la hora de realizar el firmado"
        );
      }
    }
  );
}

function utf8_to_b64(str) {
  return window.btoa(unescape(encodeURIComponent(str)));
}

// eslint-disable-next-line
function b64_to_utf8(str) {
  return decodeURIComponent(escape(window.atob(str)));
}

//FINISH DOCUMENT
async function finishFirmDocument(
  html,
  document,
  vector,
  setFinalDocumentUrlPDF,
  setLocalLoader,
  setShowDowloadDocument,
  showModalSignature,
  setSignInStep
) {
  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/signer_document?token=${process.env.REACT_APP_TOKEN}`,
    "post",
    null,
    {
      data: html,
      signDocument: document,
      signVector: vector
    }
  );
  const finishFirmDocumentData = await request.executeRequest();
  if (finishFirmDocumentData.code === 200) {
    setFinalDocumentUrlPDF(finishFirmDocumentData.data.data.data.url);
    setLocalLoader(false);
    setShowDowloadDocument(true);
    showModalSignature(false);
    setSignInStep(true);
    // MOSTRAR ALERTA EXITOSA CON EL MENSAJE QUE RETORNA LA API COMPONENTE
    setTimeout(() => {
      NotificationManager.success(
        "El firmado de documento se realizo correctamente."
      );
    }, 500);
  } else {
    setLocalLoader(false);
  }
}
