import React, { useState, useEffect } from "react";
import { Button, Form, Row, Col, Tabs, Tab } from "react-bootstrap";
import File64 from "../base_64_file/File64";
import { initValues, sendSignatureServer, validateCer, validateKey, decryptCerAndKey } from "./digital_signature_logic";
import "./digitalSignature.css";

const DigitalSignature = props => {
  //Input field Files
  const [key, setKey] = useState(null);
  const [cer, setCer] = useState(null);
  const [firelPfx, setFirelPfx] = useState(null);

  //Input Password Fields
  const [password, setPassword] = useState("");
  const [passwordFirel, setPasswordFirel] = useState("");

  const [signature, setSignature] = useState("");
  const [signedData, setSignedData] = useState("");

  const [disabledButton, setDisabledButton] = useState(false);
  const [tabFirel, setTabFirel] = useState(false);
  const [localLoader, setLocalLoader] = useState(false);

  const [localErrors, setLocalErrors] = useState({});
  const { el } = props;
  const { stage } = props;

  useEffect(() => {
    initValues(props, setSignedData, setSignature);
  }, []);

  useEffect(() => {
    if (!props.isDocument) {
      props.onChangeHandler(signature, props.el.name, props.el.type);
    }
  }, [signature]);

  const validateSignatureRequest = async () => {
    setLocalLoader(true);
    const valueResponse = await decryptCerAndKey(
      key, 
      cer, 
      password, 
      el, 
      stage, 
      setLocalLoader,
      props.showDowloadDocument,
      props.showModalSignature,
      props.setFinalDocumentUrlPDF,
      props.setSignInStep
    );

    if (valueResponse) {
      props.setState({
        ...props.state,
        [props.el.name]: {value:valueResponse.value, url:valueResponse.url}
      });
    }

    // BEGIN LAST SIGN

    // await sendSignatureServer(
    //   tabFirel,
    //   cer,
    //   key,
    //   password,
    //   firelPfx,
    //   passwordFirel,
    //   props,
    //   setSignature,
    //   signedData,
    //   props.setFinalDocumentUrlPDF,
    //   setLocalLoader,
    //   props.showDowloadDocument,
    //   props.showModalSignature,
    //   props.setSignInStep
    // );

    //ENDS LAST SIGN

    if (!props.isDocument) {
      setLocalLoader(false);
    }
  };

  const handleSetCer = file => {
    const isValidCer = validateCer(file);
    
    if (isValidCer) {
      delete localErrors['cer'];
      setCer(file);
      setDisabledButton(key !== null && password !== "");
    }
    else {
      setLocalErrors({
        ...localErrors,
        cer: ['Archivo formato incorrecto']
      });
    }
  };

  const handleSetKey = file => {
    const isValidKey = validateKey(file);

    if (isValidKey) {
      delete localErrors['key'];
      setKey(file);
      setDisabledButton(cer !== null && password !== "");
    }
    else {
      setLocalErrors({
        ...localErrors,
        key: ['Archivo formato incorrecto']
      });
    }
  };

  const handleSetFirelPfx = file => {
    setFirelPfx(file);
    setDisabledButton(firelPfx !== null && passwordFirel !== "");
  };

  const setErrorByElement = el => {
    return el.error
      ? el.error.map((el, index) => {
          return (
            <p key={index} className="w-100 mb-0">
              <small className="text-danger">{el}</small>
            </p>
          );
        })
      : "";
  };

  const handleSelect = key => {
    if (key === "keyCer") {
      setTabFirel(false);
      setDisabledButton(false);
      key !== null && cer !== null && password !== ""
        ? setDisabledButton(true)
        : setDisabledButton(false);
    } else {
      setTabFirel(true);
      setDisabledButton(false);
      firelPfx !== null && passwordFirel !== ""
        ? setDisabledButton(true)
        : setDisabledButton(false);
    }
  };

  return (
    <>
      <div
        className={`loader-container w-100 position-fixed ${
          localLoader ? "show" : ""
        }`}
      >
        <div className="overlay w-100" />
        <div className="loader position-absolute w-100">
          <img
            className="position-absolute"
            src={process.env.PUBLIC_URL + "/img/loader.gif"}
            alt="Cargando"
          />
        </div>
      </div>

      <div className="col-md-12 col-12 pT-10 pB-20">
        <Tabs
          defaultActiveKey="keyCer"
          className="tabs-signature"
          onSelect={key => handleSelect(key)}
        >
          {/**************************************************** FIRMA FIREL ****************************************************/}
          <Tab eventKey="keyCer" title="Key & Cer" className="pT-10">
            <Row>
              <Col xs={12} sm={12}>
                <File64
                  label="* Llave privada (.cer)"
                  setFile={handleSetCer}
                  id={`${props.el.name}_cerFiel`}
                  error={Object.keys(localErrors).includes('cer')}
                />
                <File64
                  label="* Llave privada (.key)"
                  setFile={handleSetKey}
                  id={`${props.el.name}_keyFiel`}
                  error={Object.keys(localErrors).includes('key')}
                />

                <Form.Group controlId="formGroupPasswordFiel">
                  <Form.Label className="font-weight-bold">
                    * Contraseña
                  </Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    onKeyUp={e =>
                      setDisabledButton(
                        key !== null && cer !== null && e.target.value !== ""
                      )
                    }
                    onChange={e => setPassword(e.target.value)}
                    value={password}
                  />
                  <Form.Text className="text-muted">
                    Esta contraseña no se guardara en ningun momento solo se
                    usara para obtener tu Fiel.
                  </Form.Text>
                </Form.Group>

                <Form.Group
                  controlId="exampleForm.ControlCadenaFirmar"
                  style={
                    props.isDocument
                      ? { display: "none", width: "100%" }
                      : { display: "inline-block", width: "100%" }
                  }
                >
                  <Form.Label className="font-weight-bold">
                    Cadena original a Firmar
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    readOnly
                    value={signedData}
                    rows={4}
                  />
                </Form.Group>

                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Label className="font-weight-bold">
                    Cadena Firmada
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="4"
                    readOnly
                    required={true}
                    value={signature}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Tab>
          {/**************************************************** FIRMA FIREL ****************************************************/}
          {/* <Tab eventKey="fpx" title="FPX" className="pT-10">
            {/*<Row>
            <Col xs={12} sm={12}>
              <h4
                className={
                  "font-weight-bold" + (props.el.error ? "text-danger" : "")
                }
              >
                {props.el.label}
              </h4>
            </Col>
          </Row>*/}
            {/*<Row>
              <Col xs={12} sm={12}>
                <File64
                  label="* Llave privada (.pfx)"
                  setFile={handleSetFirelPfx}
                  id={`${props.el.name}_pfxFirel`}
                />

                <Form.Group controlId="formGroupPasswordFirel">
                  <Form.Label className="font-weight-bold">
                    * Contraseña
                  </Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    onKeyUp={e =>
                      setDisabledButton(
                        firelPfx !== null && e.target.value !== "" && tabFirel
                      )
                    }
                    onChange={e => setPasswordFirel(e.target.value)}
                    value={passwordFirel}
                  />
                  <Form.Text className="text-muted">
                    Esta contraseña no se guardara en ningun momento solo se
                    usara para obtener tu Firel.
                  </Form.Text>
                </Form.Group>

                <Form.Group
                  controlId="exampleForm.ControlCadenaFirmarFirel"
                  style={
                    props.isDocument
                      ? { display: "none" }
                      : { display: "inline-block" }
                  }
                >
                  <Form.Label className="font-weight-bold">
                    Cadena original a Firmar
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="4"
                    readOnly
                    value={signedData}
                  />
                </Form.Group>

                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Label className="font-weight-bold">
                    Cadena Firmada
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="4"
                    readOnly
                    required={true}
                    value={signature}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Tab> */}
        </Tabs>
        <Row>
          <Col xs={12} sm={12}>
            <Form.Text className="text-muted mb-1">
              {props.el.contextual_help ? props.el.contextual_help : ""}
            </Form.Text>
            <div className="mB-10" style={{ height: "1.2rem" }}>
              {setErrorByElement(props.el)}
            </div>
          </Col>
          <Col xs={12} sm={12} className="text-center">
            <Button
              variant="primary"
              onClick={validateSignatureRequest}
              className="px-4"
              disabled={!disabledButton || signature}
            >
              Firmar electrónicamente
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default DigitalSignature;
