import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { Badge, Nav, Navbar, NavDropdown, Image } from "react-bootstrap";
import Cookies from "js-cookie";
import { logOut, setImgAvatar } from "./navbar_logic";
import { Link } from "react-router-dom";
import LoaderContext from "./loader_context";
import NotificationAlertListContext from "../../alertNotificationTable/notification_alert_context";
import DomainContext from "../../domains/domainPathContext";

const NavBar = props => {
  //GET COOKIES
  const { auth_03, names_03, paternal_surname_03, uid_03 } = Cookies.get();
  const [avatar, setAvatar] = useState("");
  const alertCount = useContext(NotificationAlertListContext);
  const [isResponsive, setIsResponsive] = useState(window.innerWidth < 992);
  const subdomain = useContext(DomainContext);
  const layout = JSON.parse(Cookies.get('layout_03'));

  useEffect(() => {
    (async function() {
      const { avatar_03 } = Cookies.get();
      if (avatar_03 && avatar_03 !== "null") {
        setAvatar(avatar_03);
      } else {
        setAvatar(`${process.env.PUBLIC_URL}/img/user_avatar.png`);
      }
    })();
  }, []);

  //INITIAL STATE
  const Loader = useContext(LoaderContext);

  //LOGOUT METHOD
  const onLogOutHandler = async () => {
    Loader.show(true);
    await logOut(props, subdomain);
    Loader.show(false);
  };

  useLayoutEffect(() => {
    function updateSize() {
      setIsResponsive(window.innerWidth < 992);
    }

    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const profileWrapper = () => {
    if (auth_03) {
      return (
        <div className="nilo-profile-wrapper">
          <div className="image-wrapper">
            <Image src={avatar} />
          </div>
          <div className="profile-info-wrapper">
            <label className="name w-100">
              {names_03} {paternal_surname_03}
            </label>
            <label className="username w-100">{uid_03}</label>
            <Link to={`/${subdomain}/edit_profile`} className="btn btn-primary">
              Editar Perfil
            </Link>
          </div>
        </div>
      );
    }
    return "";
  };

  return (
    <Navbar collapseOnSelect expand="lg">
      <Navbar.Brand as={Link} to={`/${subdomain}/home`}>
        {layout.app_header_logo_url_image !== null &&
          <img
            src={layout.app_header_logo_url_image}
            alt="application logo"
          />
        }
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          <div className={isResponsive ? "d-block" : "d-none"}>
            {profileWrapper()}
            <Nav.Link to={`/${subdomain}/alert_notifications`}>
              Alertas pendientes
              <Badge pill variant="danger" style={{ marginLeft: ".2em" }}>
                {alertCount.count === 0 ? "" : alertCount.count}
              </Badge>{" "}
            </Nav.Link>
            <Nav.Link to={`/${subdomain}/user_manual`}>
                Manual de usuario
            </Nav.Link>
            <Nav.Link to={`/${subdomain}/avatar_config`}>
              Configurar avatar
            </Nav.Link>
            <Nav.Link to={`/${subdomain}/change_password`}>
              Cambiar contraseña
            </Nav.Link>
            <NavDropdown.Divider />
            <Nav.Link onClick={onLogOutHandler}>Cerrar sesion</Nav.Link>
          </div>
        </Nav>
        <Nav>
          <div
            className={!isResponsive ? "d-block" : "d-none"}
            style={{ minWidth: "14rem" }}
          >
            <NavDropdown
              title={setImgAvatar(avatar, alertCount.count)}
              id="collasible-nav-dropdown"
              style={auth_03 ? { display: "block" } : { display: "none" }}
            >
              {profileWrapper()}
              <NavDropdown.Item
                as={Link}
                to={`/${subdomain}/alert_notifications`}
              >
                Alertas pendientes
                <Badge pill variant="danger" style={{ marginLeft: ".2em" }}>
                  {alertCount.count === 0 ? "" : alertCount.count}
                </Badge>{" "}
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to={`/${subdomain}/user_manual`}>
                  Manual de usuario
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to={`/${subdomain}/avatar_config`}>
                Configurar avatar
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to={`/${subdomain}/change_password`}>
                Cambiar contraseña
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={onLogOutHandler}>
                Cerrar sesion
              </NavDropdown.Item>
            </NavDropdown>
          </div>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavBar;
