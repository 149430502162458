import React from "react";
import { Row, Col } from "react-flexbox-grid";
import Cookies from "js-cookie";
import { Badge, Modal, Button } from "react-bootstrap";
import DeleteProcedure from "./DeleteProcedure";
import ViewDetailSelect from "./ViewDetail";
import { Request } from "../classes/Request";
import { RequestNewVersion } from "../classes/RequestNewVersion";
import { NotificationManager } from "react-notifications";
/**************************************************INICIALIZACION DE LAS COLUMNA PARA ASIGNADOS**********************************************************/

export const initColumns = [
  {
    dataField: "id",
    text: "Id",
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { width: "6%", textAlign: "center", minWidth: "3rem" }
  },
  {
    dataField: "action",
    text: "Acciones",
    sort: true,
    headerStyle: { width: "18%", textAlign: "center", minWidth: "3rem" }
  },
  {
    dataField: "name",
    text: "Trámite",
    sort: true,
    style: { textAlign: "left" },
    headerStyle: { width: "16%", textAlign: "left", minWidth: "12rem" }
  },
  {
    dataField: "applicant",
    text: "Solicitante",
    sort: true,
    style: { textAlign: "left" },
    headerStyle: { width: "14%", textAlign: "center", minWidth: "12rem" }
  },
  {
    dataField: "folio",
    text: "Folio",
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { width: "6%", textAlign: "center", minWidth: "6rem" }
  },
  {
    dataField: "status",
    text: "Estado",
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { width: "6%", textAlign: "center", minWidth: "6rem" }
  },
  {
    dataField: "created_at",
    text: "Creación",
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { width: "10%", textAlign: "center", minWidth: "8rem" }
  },
  {
    dataField: "current_stage",
    text: "Etapa Actual",
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { width: "12%", textAlign: "center", minWidth: "8rem" }
  },
  {
    dataField: "color",
    text: "Vencimiento",
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { width: "14%", textAlign: "center", minWidth: "12rem" }
  }
];

export const initColumnsProgress = [
  {
    dataField: "id",
    text: "Id",
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { width: "6%", textAlign: "center", minWidth: "3rem" }
  },
  {
    dataField: "action",
    text: "Acciones",
    sort: true,
    headerStyle: { width: "18%", textAlign: "center", minWidth: "3rem" }
  },
  {
    dataField: "name",
    text: "Trámite",
    sort: true,
    style: { textAlign: "left" },
    headerStyle: { width: "16%", textAlign: "left", minWidth: "12rem" }
  },
  {
    dataField: "applicant",
    text: "Solicitante",
    sort: true,
    style: { textAlign: "left" },
    headerStyle: { width: "14%", textAlign: "center", minWidth: "12rem" }
  },
  {
    dataField: "folio",
    text: "Folio",
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { width: "6%", textAlign: "center", minWidth: "6rem" }
  },
  {
    dataField: "created_at",
    text: "Creación",
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { width: "10%", textAlign: "center", minWidth: "8rem" }
  },
  {
    dataField: "current_stage",
    text: "Etapa Actual",
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { width: "12%", textAlign: "center", minWidth: "8rem" }
  },
  {
    dataField: "percentage_completation",
    text: "Avance",
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { width: "6%", textAlign: "center", minWidth: "6rem" }
  },
  {
    dataField: "color",
    text: "Vencimiento",
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { width: "14%", textAlign: "center", minWidth: "12rem" }
  }
];

export const initColumnsAssignAndProgress = [
  {
    dataField: "id",
    text: "Id",
    sort: true
  },
  {
    dataField: "action",
    text: "Acciones",
    sort: true
  },
  {
    dataField: "name",
    text: "Trámite",
    sort: true
  },
  {
    dataField: "applicant",
    text: "Solicitante",
    sort: true
  },
  {
    dataField: "status",
    text: "Estado",
    sort: true
  },
  {
    dataField: "folio",
    text: "Folio",
    sort: true
  },
  {
    dataField: "created_at",
    text: "Creación",
    sort: true
  },
  {
    dataField: "current_stage",
    text: "Etapa Actual",
    sort: true
  },
  {
    dataField: "percentage_completation",
    text: "Avance",
    sort: true,
    style: { textAlign: "center" }
  }
];

export const initColumnsMyProcess = [
  {
    dataField: "id",
    text: "Id",
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { width: "6%", textAlign: "center", minWidth: "3rem" }
  },
  {
    dataField: "action",
    text: "Acciones",
    sort: true,
    headerStyle: { width: "18%", textAlign: "center", minWidth: "12rem" }
  },
  {
    dataField: "name",
    text: "Trámite",
    sort: true,
    style: { textAlign: "left" },
    headerStyle: { width: "16%", textAlign: "left", minWidth: "12rem" }
  },
  {
    dataField: "folio",
    text: "Folio",
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { width: "6%", textAlign: "center", minWidth: "6rem" }
  },
  {
    dataField: "status",
    text: "Estado",
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { width: "6%", textAlign: "center", minWidth: "6rem" }
  },
  {
    dataField: "created_at",
    text: "Creación",
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { width: "10%", textAlign: "center", minWidth: "8rem" }
  },
  {
    dataField: "current_stage",
    text: "Etapa Actual",
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { width: "12%", textAlign: "center", minWidth: "8rem" }
  },
  {
    dataField: "color",
    text: "Vencimiento",
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { width: "14%", textAlign: "center", minWidth: "12rem" }
  }
];

export const initColumnsProcessPaused = [
  {
    dataField: "id",
    text: "Id",
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { width: "6%", textAlign: "center", minWidth: "3rem" }
  },
  {
    dataField: "name",
    text: "Trámite",
    sort: true,
    style: { textAlign: "left" },
    headerStyle: { width: "16%", textAlign: "left", minWidth: "12rem" }
  },
  {
    dataField: "folio",
    text: "Folio",
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { width: "6%", textAlign: "center", minWidth: "6rem" }
  },
  {
    dataField: "created_at",
    text: "Creación",
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { width: "10%", textAlign: "center", minWidth: "8rem" }
  },
  {
    dataField: "current_stage",
    text: "Etapa Actual",
    sort: true,
    style: { textAlign: "center" },
    headerStyle: { width: "12%", textAlign: "center", minWidth: "8rem" }
  },
  {
    dataField: "action",
    text: "Acciones",
    sort: true,
    headerStyle: { width: "18%", textAlign: "center", minWidth: "12rem" }
  }
];

/**********************************************************TOTAL DE TRAMITES COUNT************************************************************************/
export const getCountProcessByUser = async ( CountProcessContext ) => {
  const { userId_03 } = Cookies.get();

  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/procedure/count/${userId_03}?token=${process.env.REACT_APP_TOKEN}`,
    "get",
    null,
    {}
  );
  const getCountProcessByUserData = await request.executeRequest();
  if (getCountProcessByUserData.code === 200) {
    //NO MOSTRAR NINGUN MODAL NI RESPUESTA SOLO TRAE EL CONTADOR DE TRAMITES EN EL SIDEBAR
    CountProcessContext.setCountProcess( getCountProcessByUserData.data.data.data );
  }
};
/***********************************************************************************************************************************************/
/**********************************************************GET MY PROCESS DATA************************************************************************/
export const getMyProcessData = async (
  props,
  setProcessData,
  setTotalProcedures,
  sizePerPage,
  page,
  subdomain
) => {
  const { userId_03 } = Cookies.get();

  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/procedure/capture/${userId_03}/${sizePerPage}?page=${page}&token=${process.env.REACT_APP_TOKEN}`,
    "get",
    null,
    {}
  );
  const getMyProcessDataRequest = await request.executeRequest();
  if (getMyProcessDataRequest.code === 200) {
    //MOSTRAR ALERTA EXITOSA CON EL MENSAJE QUE RETORNA LA API COMPONENTE
    setTotalProcedures(getMyProcessDataRequest.data.data.data.total);
    const dataProcessFormatted = await FormattedActualStageMyProcesses(
      getMyProcessDataRequest.data.data.data.procedures,
      props,
      subdomain
    );
    setProcessData(dataProcessFormatted);
  } else {
    //MOSTRAR ALERTA ERRONEA CON EL MENSAJE QUE RETORNA LA API COMPONENTE
    setProcessData([]);
  }
};
/***********************************************************************************************************************************************/
/***********************FORMATEAR EL CURRENT CUANDO VIENEN LOS TRAMITES DE CAPTURA DE UN FUNCIONARIO****************/
export const FormattedActualStageMyProcesses = async (
  procedures,
  props,
  subdomain
) => {
  let myProcedureList = [];
  let newObject = {};
  procedures.forEach(el => {
    //SI EL EL.ACTION.DELETE ESTA SETEADO EL USUARIO PODRA ELIMINAR EL REGISTRO DE LO CONTRARIO NO SE MOSTRARA LA OPCION
    const DeletedElementComponent = el.actions.delete ? (
      <DeleteProcedure el={el} props={props} />
    ) : (
      ""
    );

    el.action = (
      <Row center="xs" around="xs">
        <Col xs={12} className="procedures-actions">
          <div
            className="edit"
            onClick={() => resumeProcess(el.id, props, subdomain)}
          >
            <i
              className="ti-pencil-alt mr-1"
              title="Continuar edición del trámite"
            />
          </div>
          {DeletedElementComponent}
        </Col>
      </Row>
    );
    newObject = {
      ...el,
      color: printColorStatus(el.stages[0]),
      created_at: formatDate(el.created_at)
    };
    myProcedureList.push(newObject);
  });
  return myProcedureList;
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////FUNCION QUE EJECUTA EL FORMULARIO EN EL CUAL SE QUEDO EL USUARIO, MANDA LOS DATOS AL REDIRIGIR AL FORMULARIO///////////////////////////////////////////////

export const resumeProcess = async (procedure_id, props, subdomain) => {
  const { userId_03 } = Cookies.get();

  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/procedure/continue/${procedure_id}/${userId_03}?token=${process.env.REACT_APP_TOKEN}`,
    "get",
    null,
    {}
  );

  const resumeProcessData = await request.executeRequest();
  if (resumeProcessData.code === 200) {
    //MOSTRAR ALERTA EXITOSA CON EL MENSAJE QUE RETORNA LA API COMPONENTE
    await getFormBasedInUrl(
      resumeProcessData.data.data.data.next_url,
      props,
      subdomain
    );
  }
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const getFormBasedInUrl = async (url, props, subdomain) => {
  const request = new Request(url, "get", null, {});
  const formDataBasedUrl = await request.executeRequest();
  if (formDataBasedUrl.code === 200) {
    //MOSTRAR ALERTA EXITOSA CON EL MENSAJE QUE RETORNA LA API COMPONENTE
    const meta = formDataBasedUrl.data.data.data.meta;
    const form = formDataBasedUrl.data.data.data.form;

    props.history.push({
      pathname: `/${subdomain}/start_process`,
      state: {
        params: {
          stage: meta.stage_id,
          sequence: meta.current_step,
          form: form,
          formTitle: meta.form_name,
          previous_url: formDataBasedUrl.data.data.data.previous_url,
          mode: meta.mode,
          applicant: meta.applicant_id
        }
      }
    });
  }
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**************************************************LISTADO DE TRAMITES ASIGNADOS**************************************************/
/**********************************************************getAssignProcessData************************************************************************/
export const getAssignProcessData = async (
  props,
  setProcessData,
  setTotalProcedures,
  sizePerPage,
  page
) => {
  const { userId_03 } = Cookies.get();
  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/procedure/attended/${userId_03}/${sizePerPage}?page=${page}&token=${process.env.REACT_APP_TOKEN}`,
    "get",
    null,
    {}
  );

  const getAssignProcessDataRequest = await request.executeRequest();
  if (getAssignProcessDataRequest.code === 200) {
    //NO MOSTRAR NINGUN MODAL NI RESPUESTA SOLO TRAE EL CONTADOR DE TRAMITES EN EL SIDEBAR
    setTotalProcedures(getAssignProcessDataRequest.data.data.data.total);
    const procedures = await FormattedActualStageAttend(
      getAssignProcessDataRequest.data.data.data.procedures,
      props
    );
    setProcessData(procedures);
  }
};

export const FormattedActualStageAttend = async procedures => {
  procedures.forEach(el => {
    el.created_at = formatDate(el.created_at);
    el.action = (
      <Row center="xs" around="xs">
        <Col xs={12}>
          <ViewDetailSelect el={el} />
        </Col>
      </Row>
    );
    el.percentage_completation = getProgessTag(el.percentage_completation);
  });
  return procedures;
};
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**************************************************LISTADO DE TRAMITES PENDIENTES**************************************************/
export const getPendingProcessData = async (
  props,
  setProcessData,
  setTotalProcedures,
  sizePerPage,
  page,
  subdomain,
  CountProcess,
  setShowModal,
  setElementsModal
) => {
  const { userId_03 } = Cookies.get();
  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/procedure/to_attend/${userId_03}/${sizePerPage}?page=${page}&token=${process.env.REACT_APP_TOKEN}`,
    "get",
    null,
    {}
  );
  const getPendingProcessDataRequest = await request.executeRequest();
  const reloadDataWhenPauseProcedure = {
    setProcessData: setProcessData,
    setTotalProcedures: setTotalProcedures,
    sizePerPage: sizePerPage,
    page: page,
    contextToCountProcess: CountProcess
  }

  if (getPendingProcessDataRequest.code === 200) {
    //NO MOSTRAR NINGUN MODAL NI RESPUESTA SOLO TRAE EL CONTADOR DE TRAMITES EN EL SIDEBAR
    setTotalProcedures(getPendingProcessDataRequest.data.data.data.total);
    const procedures = await FormattedActualStagePending(
      getPendingProcessDataRequest.data.data.data.procedures,
      props,
      subdomain,
      reloadDataWhenPauseProcedure,
      setShowModal,
      setElementsModal
    );
    setProcessData(procedures);
  }
};

export const FormattedActualStagePending = async (
  procedures,
  props,
  subdomain,
  reloadDataWhenPauseProcedure,
  setShowModal,
  setElementsModal
) => {
  const { userId_03, special_permissions_03 } = Cookies.get();
  let myProcedureList = [];
  let newObject = {};
  const canPauseProcedures = JSON.parse(special_permissions_03)[0]["pause"] === "1" ? true : false;

  procedures.forEach(el => {
    el.action = (
      <Row center="xs" around="xs">
        <Col xs={12} className="procedures-actions">
          <div
            className="continue"
            onClick={() => resumeProcess(el.id, props, subdomain)}
          >
            <i className="ti-arrow-circle-right" title="Continuar tramite" />
          </div>
          {
            canPauseProcedures && el.actions && el.actions.pause ?
              <div className="continue" onClick={() => activateModalToPauseProcess(el.actions.pause, props, reloadDataWhenPauseProcedure, setShowModal, setElementsModal)}>
                <i className="ti-control-pause" title="Pausar tramite" />
              </div> :
              null
          }
        </Col>
      </Row>
    );
    el.stages.forEach(stage => {
      if (parseInt(stage.user_id) === parseInt(userId_03)) {
        newObject = {
          ...el,
          color: printColorStatus(stage),
          percentage_completation: getProgessTag(el.percentage_completation),
          expired_at: "",
          created_at: formatDate(el.created_at)
        };
        myProcedureList.push(newObject);
      }
    });
  });
  return myProcedureList;
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**************************************************LISTADO DE TRAMITES NO ASIGNADOS**************************************************/

export const getListNoAssignProcessData = async (
  setShowModalHandler,
  props,
  setProcessData,
  setTotalProcedures,
  sizePerPage,
  page
) => {
  const { userId_03 } = Cookies.get();
  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/procedure/unassigned/${userId_03}/${sizePerPage}?page=${page}&token=${process.env.REACT_APP_TOKEN}`,
    "get",
    null,
    {}
  );
  const getListNoAssignProcessDataRequest = await request.executeRequest();
  if (getListNoAssignProcessDataRequest.code === 200) {
    //NO MOSTRAR NINGUN MODAL NI RESPUESTA SOLO TRAE EL CONTADOR DE TRAMITES EN EL SIDEBAR
    setTotalProcedures(getListNoAssignProcessDataRequest.data.data.data.total);
    const procedures = await FormattedActualStageUnassigned(
      setShowModalHandler,
      getListNoAssignProcessDataRequest.data.data.data.procedures,
      props
    );
    setProcessData(procedures);
  }
};

export const FormattedActualStageUnassigned = async (
  setShowModalHandler,
  procedures
) => {
  let myProcedureList = [];
  let newObject = {};
  procedures.forEach(el => {
    el.action = (
      <Row center="xs" around="xs">
        <Col xs={12} className="procedures-actions">
          <div
            className="take"
            onClick={() => setShowModalHandler(el.stages[0].id)}
          >
            <i className="ti-user" title="Asignarme este tramite" />
          </div>
        </Col>
      </Row>
    );
    newObject = {
      ...el,
      color: printColorStatus(el.stages[0]),
      expired_at: "",
      created_at: formatDate(el.created_at)
    };
    myProcedureList.push(newObject);
  });
  return myProcedureList;
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/*****************************************REQUEST PARA ASIGNAR UN TRAMITE PARA EL FUNCIONARIO ACTUAL******************************/

export const toAssignProcess = async (stage_id, props, subdomain) => {
  const { userId_03, domain_name_03 } = Cookies.get();

  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/stage/assign/${stage_id}`,
    "put",
    null,
    {
      token: process.env.REACT_APP_TOKEN,
      user_id: userId_03
    }
  );
  const toAssignProcessData = await request.executeRequest();
  if (toAssignProcessData.code === 200) {
    //NO MOSTRAR NINGUN MODAL NI RESPUESTA SOLO TRAE EL CONTADOR DE TRAMITES EN EL SIDEBAR
    props.history.push(`/${domain_name_03}/success_autoassign`);
  }
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const expiration_date = el => {
  if (el.expiration_date) {
    let d = new Date(el.expiration_date.replace(/-/g, "/"));
    return (
      d.getDate() +
      "-" +
      (d.getMonth() < 9 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1) +
      "-" +
      d.getFullYear()
    );
  }
  return "";
};

const formatDate = DateString => {
  let date = new Date(DateString.replace(/-/g, "/"));
  let month = date.getMonth() + 1;
  return (
    date.getDate() +
    "-" +
    (month < 9 ? "0" : "") +
    month +
    "-" +
    date.getFullYear()
  );
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const printColorStatus = el => {
  switch (el.color) {
    case "green":
      return (
        <Badge
          variant="success"
          title={
            el.expiration_date ? `Días restantes : ${el.remaining_days}` : ""
          }
        >
          {el.expiration_date ? expiration_date(el) : "A tiempo"}
        </Badge>
      );
    case "yellow":
      return (
        <Badge
          variant="warning"
          title={
            el.expiration_date ? `Días restantes : ${el.remaining_days}` : ""
          }
        >
          {expiration_date(el)}
        </Badge>
      );
    case "red":
      return (
        <Badge
          variant="danger"
          title={el.expiration_date ? `Trámite vencido` : ""}
        >
          {expiration_date(el)}
        </Badge>
      );
    default:
      break;
  }
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const getProgessTag = progress => {
  if (progress !== "") {
    return <span className="text-success font-weight-bold">{progress}%</span>;
  }

  return <span className="text-success font-weight-bold">0%</span>;
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// ABRE MODAL PARA PAUSAR UN TRÁMITE
const activateModalToPauseProcess = ( urlToPause, props, reloadDataProcessActive, setShowModal, setElementsModal ) => {
  var text;
  setElementsModal(
    <>
      <Modal.Header>
        <Modal.Title>Pausar trámite</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Es necesaria una justificación para llevar a cabo esta acción. Posterior a argumentar la justificación
        haz clic en el botón Aceptar, de lo contrario oprime el botón Cancelar.
        <br/>
        <label className="mT-20" id="labelJustification"><strong>* Justificación</strong></label>
        <textarea 
          id="textJustification" 
          rows="8"
          onChange={(e) => { 
            document.getElementById("labelJustification").style.color = "#72777a";
            e.target.style.border = "0.06rem solid #C1C1C1";
            text = e.target.value 
          }} 
          style={{
            width:"100%",
            borderRadius:"5px", 
            border: "0.06rem solid #C1C1C1",
            color: "#495057"
          }}
          maxLength="250"
          >
        </textarea>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowModal(false)}>
          Cancelar
        </Button>
        <Button
          variant="primary"
          onClick={() => pauseProcess( urlToPause, reloadDataProcessActive, props, setShowModal, setElementsModal, text )}
        >
          Aceptar
        </Button>
      </Modal.Footer>
    </>
  );
  setShowModal(true);
}; 
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// TRAE LOS DATOS DE LOS TRÁMITES PAUSADOS Y SETEA LOS DATOS EN EL STATE
export const getProcessPausedData = async (
  props,
  setProcessData,
  setTotalProcedures,
  sizePerPage,
  page,
  CountProcess,
  setShowModal,
  setElementsModal
) => {
  const { userId_03 } = Cookies.get();

  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/procedures_paused/${userId_03}/${sizePerPage}?page=${page}&token=${process.env.REACT_APP_TOKEN}`,
    "get",
    null,
    {}
  );
  const getMyProcessDataRequest = await request.executeRequest();
  const reloadDataProcessPaused = {
    setProcessData: setProcessData,
    setTotalProcedures: setTotalProcedures,
    sizePerPage: sizePerPage,
    page: page,
    contextToCountProcess: CountProcess
  };

  if (getMyProcessDataRequest.code === 200) {
    //MOSTRAR ALERTA EXITOSA CON EL MENSAJE QUE RETORNA LA API COMPONENTE
    setTotalProcedures(getMyProcessDataRequest.data.data.data.total);
    const dataProcessFormatted = await FormattedActualStageProcessesPaused(
      getMyProcessDataRequest.data.data.data.procedures,
      props,
      reloadDataProcessPaused,
      setShowModal,
      setElementsModal
    );
    setProcessData(dataProcessFormatted);
  } else {
    //MOSTRAR ALERTA ERRONEA CON EL MENSAJE QUE RETORNA LA API COMPONENTE
    setProcessData([]);
  }
};

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// SETEA LOS DATOS EN EL FORMATO CORRECTO PARA ENLISTARLOS
export const FormattedActualStageProcessesPaused = async (procedures, props, reloadDataProcessPaused, setShowModal, setElementsModal) => {
  let myProcedureList = [];
  let newObject = {};

  procedures.forEach(el => {

    el.id = el.procedure_id;
    el.action = (
      <Row center="xs" around="xs">
      <Col xs={12} className="procedures-actions">
        <div className="continue" onClick={() => activateModalToActiveProcess( el.actions.activate, props, reloadDataProcessPaused, setShowModal, setElementsModal )}>
          <i className="ti-arrow-circle-right" title="Activar tramite" />
        </div>
      </Col>
    </Row>
    );

    newObject = {
      ...el,
      created_at: formatDate(el.created_at)
    };

    myProcedureList.push(newObject);
  });
  return myProcedureList;
};

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// EJECUTA EL ENDPOINT PARA CAMBIAR EL STATUS DE UN TRÁMITE A PAUSADO
export const pauseProcess = async ( urlToPause, reloadDataWhenPauseProcedure, props, setShowModal, setElementsModal, justification ) => {
  const request = new Request(
    urlToPause + "?token=" + process.env.REACT_APP_TOKEN,
    "post",
    null,
    {
      justification:justification
    }
  );
  const pauseProcessData = await request.executeRequest();
  if ( pauseProcessData.code === 200 ) {
    await getPendingProcessData(
      props,
      reloadDataWhenPauseProcedure.setProcessData,
      reloadDataWhenPauseProcedure.setTotalProcedures,
      reloadDataWhenPauseProcedure.sizePerPage,
      reloadDataWhenPauseProcedure.page,
      reloadDataWhenPauseProcedure.contextToCountProcess,
      setShowModal,
      setElementsModal
    )
    await getCountProcessByUser( reloadDataWhenPauseProcedure.contextToCountProcess );
    setShowModal(false);
    NotificationManager.success("Tu trámite ha sido pausado satisfactoriamente.");
  }
  else {
    const inputJustification = document.getElementById("textJustification");
    const labelJustification = document.getElementById("labelJustification");
    inputJustification.style.border = "0.06rem solid red";  
    labelJustification.style.color = "red";    }

};

// ACTIVA EL MODAL PARA ACTIVAR UN TRAMITE Y SETEA LOS ELEMENTOS DEL RESPECTIVO MODAL
const activateModalToActiveProcess = ( urlToActivate, props, reloadDataProcessPaused, setShowModal, setElementsModal ) => {
  var text;
  setElementsModal(
    <>
      <Modal.Header>
        <Modal.Title>Reanudar trámite</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Es necesaria una justificación para llevar a cabo esta acción. Posterior a argumentar la justificación
        haz clic en el botón Aceptar, de lo contrario oprime el botón Cancelar.
        <br/>
        <label className="mT-20" id="labelJustification"><strong>* Justificación</strong></label>

        <textarea 
          id="textJustification" 
          rows="8"
          onChange={(e) => { 
            document.getElementById("labelJustification").style.color = "#72777a";
            e.target.style.border = "0.06rem solid #C1C1C1";
            text = e.target.value 
          }} 
          style={{
            width:"100%",
            borderRadius:"5px", 
            border: "0.06rem solid #C1C1C1",
            color: "#495057"
          }}
          maxLength="250"
          >
        </textarea>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowModal(false)}>
          Cancelar
        </Button>
        <Button
          variant="primary"
          onClick={() => activateProcess( urlToActivate, props, reloadDataProcessPaused, setShowModal, setElementsModal, text )}
        >
          Aceptar
        </Button>
      </Modal.Footer>
    </>
  );
  setShowModal(true);
};
// EJECUTA EL ENDPOINT PARA ACTIVAR UN TRÁMITE
export const activateProcess = async ( urlToActivate, props, reloadDataProcessPaused, setShowModal, setElementsModal, justification ) => {
  const request = new Request(
    urlToActivate + "?token=" + process.env.REACT_APP_TOKEN,
    "post",
    null,
    {
      justification: justification
    }
  );
  const activateProcessData = await request.executeRequest();
  if ( activateProcessData.code === 200 ) {
    await getProcessPausedData(
      props,
      reloadDataProcessPaused.setProcessData,
      reloadDataProcessPaused.setTotalProcedures,
      reloadDataProcessPaused.sizePerPage,
      reloadDataProcessPaused.page,
      reloadDataProcessPaused.contextToCountProcess,
      setShowModal,
      setElementsModal
    )
    await getCountProcessByUser( reloadDataProcessPaused.contextToCountProcess );
    setShowModal(false);
    NotificationManager.success("Tu trámite ha sido reactivado satisfactoriamente.");
  }
  else {
    const inputJustification = document.getElementById("textJustification");
    const labelJustification = document.getElementById("labelJustification");
    inputJustification.style.border = "0.06rem solid red";  
    labelJustification.style.color = "red";  
  }

};


export const executeSearchToProceduresToAttend = async (
  valueToSearch,
  page,
  sizePerPage,
  setData,
  props,
  setTotalProcedures,
  subdomain,
  CountProcess,
  setShowModal,
  setElementsModal,
  setTextNotData
) => {
  const { userId_03 } = Cookies.get();

  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/procedure/searching/to_attend/${userId_03}/${valueToSearch}/${sizePerPage}?page=${page}&token=${process.env.REACT_APP_TOKEN}`,
    "get",
    null,
    {}
  ); 

  const responseOfSearch = await request.executeRequest();
  const reloadDataWhenPauseProcedure = {
    setProcessData: setData,
    setTotalProcedures: setTotalProcedures,
    sizePerPage: sizePerPage,
    page: page,
    contextToCountProcess: CountProcess
  }

  if (responseOfSearch.code === 200) {

    setTotalProcedures(responseOfSearch.response.total);
    const procedures = await FormattedActualStagePending(
      responseOfSearch.response.procedures,
      props,
      subdomain,
      reloadDataWhenPauseProcedure,
      setShowModal,
      setElementsModal
    );
    if (procedures.length === 0) setTextNotData("Sin resultados");
    setData(procedures);
  }

};


export const executeSearchToProceduresNoAssign = async (
  valueToSearch,
  setShowModalHandler,
  props,
  setData,
  setTotalProcedures,
  sizePerPage,
  page,
  setTextNotData
) => {
  const { userId_03 } = Cookies.get();

  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/procedure/searching/unassigned/${userId_03}/${valueToSearch}/${sizePerPage}?page=${page}&token=${process.env.REACT_APP_TOKEN}`,
    "get",
    null,
    {}
  ); 

  const responseOfSearch = await request.executeRequest();
  
  if (responseOfSearch.code === 200) {
    //NO MOSTRAR NINGUN MODAL NI RESPUESTA SOLO TRAE EL CONTADOR DE TRAMITES EN EL SIDEBAR
    setTotalProcedures(responseOfSearch.response.total);
    const procedures = await FormattedActualStageUnassigned(
      setShowModalHandler,
      responseOfSearch.response.procedures,
      props
    );
    if (procedures.length === 0) setTextNotData("Sin resultados");
    setData(procedures);
  }
};

export const executeSearchToProceduresAttended = async (
  valueToSearch,
  setShowModalHandler,
  props,
  setData,
  setTotalProcedures,
  sizePerPage,
  page,
  setTextNotData
) => {
  const { userId_03 } = Cookies.get();

  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/procedure/searching/attended/${userId_03}/${valueToSearch}/${sizePerPage}?page=${page}&token=${process.env.REACT_APP_TOKEN}`,
    "get",
    null,
    {}
  ); 

  const responseOfSearch = await request.executeRequest();

  if (responseOfSearch.code === 200) {
    setTotalProcedures(responseOfSearch.response.total);
    const procedures = await FormattedActualStageAttend(
      responseOfSearch.response.procedures,
      props
    );
    if (procedures.length === 0) setTextNotData("Sin resultados");
    setData(procedures);
  }

}