/* eslint-disable no-const-assign */
import axios from "axios";
import {animateScroll} from "react-scroll";
import { NotificationManager } from "react-notifications";
import Cookies from "js-cookie";
import { DestroyCookies } from "../login/login_logic";
export class Request {
  constructor(url, method, headers, bodyRequest) {
    this.url = url;
    this.bodyRequest = bodyRequest;
    this.method = method;
    this.headers = headers;
  }

  async executeRequest(show = true) {
    let responseError = {
      code: 800,
      message:
        "Hubo un error al realizar la accion, intentelo mas tarde o contacte al administrador."
    };

    const authenticationToken = Cookies.get("authentication_token_03") ?
                                String(Cookies.get("authentication_token_03")) :
                                btoa(process.env.REACT_APP_TOKEN_WITHOUT_AUTHENTICATION);

    try {
      const response = await axios({
        method: this.method,
        url: this.url,
        data: this.bodyRequest,
        headers: {
          Authorization: authenticationToken
        }
      });

      if (response.data.code === 403 && response.data.data.clean) {
        DestroyCookies();
        window.location.reload();
      } 

      // eslint-disable-next-line eqeqeq
      if (response.data.code == "200")
        return {
          code: response.data.code,
          data: response
        };
      else if (typeof response.data === "object" && response.data[0] && response.data[0].hasOwnProperty('label')) {
        return response.data;
      }

      //MOSTRAR COMPONENTE ALERT, CON EL DATA DEL RESPONSE DEL ERROR
      if (
        response.data.data.error &&
        typeof response.data.data.message == "string"
      ) {
        if (typeof response.data.data.code != "undefined") {
          responseError = {
            code: response.data.data.code,
            message: response.data.data.message
          };
        } else {
          responseError = {
            code: response.data.code,
            message: response.data.data.message
          };
        }
      } else if (typeof response.data.data.form == "object") {
        NotificationManager.error("Existen errores que impiden continuar, por favor revisa los campos remarcados en color rojo.");
        animateScroll.scrollToTop();

        return {
          code: response.data.code,
          form: response.data.data.form
        };
      } else if (typeof response.data.data.message == "object") {
        // case for error in the form fields.
        if (show) {
          for (const [field, errorlist] of Object.entries(
            response.data.data.message
          )) {
            for (let iE = 0; iE < errorlist.length; errorlist++) {
              let description = field !== 'file' ? ("Error en " + field + ":") : ("Error");

              NotificationManager.error(
                errorlist[iE],
                description
              );
            }
          }
        }

        return {
          code: response.data.code,
          message: response.data.data.message
        };
      } else if (typeof response.data.data.error == "object") {
        // case for error in the form fields.
        if (show) {
          for (const [field, errorlist] of Object.entries(
            response.data.data.error
          )) {
            for (let iE = 0; iE < errorlist.length; errorlist++) {
              NotificationManager.error(
                errorlist[iE],
                "Error en " + field + ":"
              );
            }
          }
        }

        return {
          code: response.data.code,
          message: response.data.data.error
        };
      } else
        responseError = {
          code: response.data.code,
          message: response.data.data.error
        };
    } catch (error) {
      if (error.response) {
        //MOSTRAR COMPONENTE ALERT, CON EL DATA DEL RESPONSE DEL ERROR
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        /* console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers); */
        responseError = {
          code: error.response.status,
          message: error.response.data
        };
      } else if (error.request) {
        //MOSTRAR COMPONENTE ALERT, CON EL DATA DEL RESPONSE DEL ERROR
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        responseError = {
          //MOSTRAR COMPONENTE ALERT, CON EL DATA DEL RESPONSE DEL ERROR
          code: 500,
          message: "El servidor no responde"
        };
      }
    }

    if (show) {
      NotificationManager.error(
        responseError.message,
        "Error " + responseError.code + ":"
      );
    }

    return responseError;
  }

  //   // Getter
  //   get area() {
  //     return this.calcArea();
  //   }
  //   // Método
  //   calcArea() {
  //     return this.height * this.width;
  //   }
}
