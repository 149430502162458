import React, { useEffect, useState } from "react";
import TinyEditor from "../tiny_libray/TinyPage";
import TinySignature from "../tiny_libray/TinySignature";
import DynamicPDF from "../react_pdf/PDF";

const DigitalDocument = props => {
  return (
    <>
      {
        props.el.extra_attributes.document_information.type === "pdf" ? (
          <DynamicPDF
            url={props.el.extra_attributes.document_information.data}
            {...props}
          />
        ) : !props.el.extra_attributes.signature ? 
            ( <TinyEditor {...props} /> ) 
          : 
            ( <TinySignature {...props} /> )
      }
    </>
  );
};

export default DigitalDocument;
