import Cookies from "js-cookie";
import { RequestNewVersion } from "../classes/RequestNewVersion";
import { NotificationManager } from "react-notifications";

/**********************************************************SAVE AVATAR************************************************************************/
export const uploadAvatar = async (file64, subdomain) => {
  const { userId_03 } = Cookies.get();
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/upload_avatar/${userId_03}`,
    "post",
    null,
    {
      avatar: file64
    }
  );
  const uploadAvatarData = await request.executeRequest();

  if (
    uploadAvatarData.code === 200 && 
    uploadAvatarData.response.url && 
    uploadAvatarData.response.url !== "" && 
    typeof uploadAvatarData.response.url === "string" ) {

      NotificationManager.success(uploadAvatarData.response.message);

      setTimeout(() => {
        Cookies.set("avatar_03", uploadAvatarData.response.url);
        window.location.href = `/${subdomain}/home`;
      }, 800);
  } else {

    NotificationManager.error("No fue posible actualizar el avatar, inténtalo nuevamente");
  }
};
/***********************************************************************************************************************************************/
