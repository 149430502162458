import React, { useState, useEffect, useContext } from "react";
import { RemotePagination } from "../helper_components/remote_pagination/RemotePagination";
import {
  getAssignProcessData,
  initColumnsAssignAndProgress,
  executeSearchToProceduresAttended
} from "./table_process_logic";
import LoaderContext from "../layout/shared/loader_context";
//import Loader from "../layout/shared/Loader";
import Searcher from "../helper_components/searcher_component/Searcher";
import { Search } from "react-bootstrap-table2-toolkit";

const TableProcessAsign = props => {
  const [columns] = useState(initColumnsAssignAndProgress);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(5);
  const [totalProcedures, setTotalProcedures] = useState(5);
  const [ setShowModal ] = useState(false);
  const [ setStageState] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [isCleaning, setIsCleaning] = useState(false);
  const [search, setSearch] = useState("");
  const loader = useContext(LoaderContext);
  const [textNotData, setTextNotData] = useState("");

  function setShowModalHandler(stage_id) {
    setShowModal(true);
    setStageState(stage_id);
  }

  useEffect(() => {
    (async function() {
      await getAssignProcessData(
        props,
        setData,
        setTotalProcedures,
        sizePerPage,
        page
      );
    })();
  }, []);

  const handleTableChange = async (type, { page, sizePerPage }) => {
    setPage(page);
    setSizePerPage(sizePerPage);

    if(Search.length > 0 && /^$|.*\S+.*/.test(search)) {
      loader.show(true);
      await executeSearchToProceduresAttended(
        search,
        setShowModalHandler,
        props,
        setData,
        setTotalProcedures,
        sizePerPage,
        page,
        setTextNotData
      );
      loader.show(false);
    } else {
      loader.show(true);
      await getAssignProcessData(
        props,
        setData,
        setTotalProcedures,
        sizePerPage,
        page
      );
      loader.show(false);
    }
  };

  const getProceduresToSearch = async () => {
    if (search.length > 0 && /^$|.*\S+.*/.test(search)) {

      setIsSearching(true);
      loader.show(true);
      setTextNotData("");
      await executeSearchToProceduresAttended(
        search,
        setShowModalHandler,
        props,
        setData,
        setTotalProcedures,
        sizePerPage,
        1,
        setTextNotData
      );
      setIsSearching(false);
      loader.show(false);
    }
  };

  const cleanProceduresToSearch = async () => {
    setSearch("");
    setIsCleaning(true);
    loader.show(true);
    await getAssignProcessData(
      props,
      setData,
      setTotalProcedures,
      sizePerPage,
      1
    );
    setTextNotData("");
    setIsCleaning(false);
    loader.show(false);  
  };

  return (
    <>
      <h4 className="mB-20 mt-0 w-100 text-center">Trámites atendidos</h4>

      <Searcher
        placeholderSearch="Ingresa el texto a buscar"
        placeholderSearchButton="Buscar "
        placeholderCleanButton="Limpiar"
        helperContextual="Ingresa el texto deseado y haz clic en el botón 'Buscar'"
        executeSearch={getProceduresToSearch}
        cleanSearch={cleanProceduresToSearch}
        isSearching={isSearching}
        isCleaning={isCleaning}
        setSearch={setSearch}
        search={search}
        classContainer="classContainer"
        classHelperContextual="classHelperContextual"
        classButtonsSearch="classButtonsSearch"
      />

      { data.length > 0 && textNotData === '' ?
        <RemotePagination
          data={data}
          page={page}
          sizePerPage={sizePerPage}
          totalSize={totalProcedures}
          onTableChange={handleTableChange}
          columns={columns}
        />
        :
        <h4 className="clearFloat text-center mT-20">{textNotData}</h4>
      }
    </>
  );
};
export default TableProcessAsign;
