import React, { useContext } from "react";
import { Card, Alert, Button } from "react-bootstrap";
import { Col, Row } from "react-flexbox-grid";
import { Link } from "react-router-dom";
import DomainContext from "../domains/domainPathContext";

const SuccessAutoassign = props => {
  const subdomain = useContext(DomainContext);

  return (
    <Row className="mT-30">
      <Col xs={12} md={1} />
      <Col xs={12} md={10}>
        <Card>
            <Card.Header>Estatus de la operación</Card.Header>
            <Card.Body>
            <Row start="xs">
                <Col xs={12}>
                <Alert variant="success">
                    <Alert.Heading>Operación exitosa</Alert.Heading>
                    <p>Te has asignado correctamente el trámite, haz clic en el botón “Continuar” para completar el proceso.</p>
                    <hr />
                </Alert>

                <div className="d-flex justify-content-end">
                    <Link to={`/${subdomain}/table_process/no_assign`}>
                    <Button variant="outline-success">Continuar</Button>
                    </Link>
                </div>
                </Col>
            </Row>
            </Card.Body>
        </Card>
      </Col>
      <Col xs={12} md={1} />
    </Row>
  );
};

export default SuccessAutoassign;
