import React, { useState } from "react";
import ReactMapboxGl, { Layer, Feature } from "react-mapbox-gl";
import { Form } from "react-bootstrap";
import _ from "lodash";
import Cookies from "js-cookie";
import Geocoder from "react-mapbox-gl-geocoder";
import "./map.css";
import { Col } from "react-flexbox-grid";

const queryParams = {
  country: "mx"
};

const DynamicMap = props => {
  const [viewport, setViewport] = useState({});

  const [mapLongitude, setMapLongitude] = useState(
    !_.isObject(props.el.value)
      ? props.el.extra_attributes.longitude
      : props.el.value.value.longitude
  );
  const [mapLatitude, setMapLatitude] = useState(
    !_.isObject(props.el.value)
      ? props.el.extra_attributes.latitude
      : props.el.value.value.latitude
  );

  const onMarkerDragEnd = async event => {
    await centerMap(event.lngLat.lat, event.lngLat.lng);
    props.onChangeHandler(
      { latitude: event.lngLat.lat, longitude: event.lngLat.lng },
      props.el.name,
      props.el.type
    );
  };

  const centerMap = async (lat, long) => {
    setMapLatitude(lat);
    setMapLongitude(long);
  };

  const disableFieldBasedMethod = el => {
    const { userId_03 } = Cookies.get();

    if (props.mode === 3 || props.mode === 2) {
      return false;
    }
    
    if (props.mode === 1 && el.readonly === 1 ) { 
      return false;
    }

    if (parseInt(userId_03) !== parseInt(props.applicant)) {
      return false;
    } 
    
    return true;
  };

  const Map = ReactMapboxGl({
    accessToken: process.env.REACT_APP_MAP_TOKEN,
    interactive: disableFieldBasedMethod(props.el)
  });
  
  const onSelectedGoecoder = async (viewport, item) => {
    setViewport({ viewport });
    await centerMap(viewport.latitude, viewport.longitude);
    props.onChangeHandler(
      { latitude: viewport.latitude, longitude: viewport.longitude },
      props.el.name,
      props.el.type
    );
  };

  const settingDefaultValues = (e) =>{
    if(e.target.value === "Buscar..." ) return e.target.value = "";
  };

  return (
    <Col
      className="p-2 pt-3 h-100"
      xs={12}
    >
      <Form.Label className={props.el.error ? "text-danger" : ""}>
        <span
          style={
            props.el.requiredSignal
              ? { display: "inline" }
              : { display: "none" }
          }
        >
          <b>* </b>
        </span>
        <b>{props.el.label}</b>
        <span
          className="observation-input-icon"
          style={{
            display:
              props.el.is_validable && props.mode === 3
                ? "inline-block"
                : "none"
          }}
          onClick={() => props.setShow(props.el)}
        >
          <i
            className={
              "far fa-comment-dots" +
              (props.el.observations ? " text-danger" : "")
            }
          />
        </span>
      </Form.Label>

      <div onClick={(e)=>settingDefaultValues(e)}>
        <Geocoder
          initialInputValue="Buscar..."
          mapboxApiAccessToken={process.env.REACT_APP_MAP_TOKEN}
          onSelected={onSelectedGoecoder}
          viewport={viewport}
          hideOnSelect={true}
          queryParams={queryParams}
        />
      </div>
      <Map
        // eslint-disable-next-line react/style-prop-object
        style={process.env.REACT_APP_MAP_STYLES}
        containerStyle={{
          width: "100%",
          height: "35rem",
          minHeight: "35rem"
        }}
        zoom={[props.el.extra_attributes.zoom]}
        center={[mapLongitude, mapLatitude]}
      >
        <Layer type="circle" id="marker" paint={{
          'circle-color': "#fff",
          'circle-stroke-width': 10,
          'circle-stroke-color': '#ff5200',
          'circle-stroke-opacity': 1
        }}>
          <Feature
            coordinates={[mapLongitude, mapLatitude]}
            draggable={disableFieldBasedMethod(props.el)}
            onDragEnd={onMarkerDragEnd}
          />
        </Layer>
      </Map>
      <small id={`small${props.el.name}`} className="form-text text-muted">
        {props.el.contextual_help ? props.el.contextual_help : ""}
      </small>
      {props.errorsMap}
    </Col>
  );
};

export default DynamicMap;
