import { Request } from "./components/classes/Request";
import Cookies from "js-cookie";

/**********************************************************GET DOMAIN NAME************************************************************************/
export const domainRequest = async domainName => {
  const isAuth = Cookies.get("auth_03") ? Cookies.get("auth_03") : null;
  
  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/domains/find_by_name?token=${process.env.REACT_APP_TOKEN}&domainName=${domainName}`,
    "get",
    null,
    {}
  );

  if ( isAuth ) {
    return "Normal";
  }
  else {

    const domainRequestData = await request.executeRequest();
    if (domainRequestData.code === 200) {

      Cookies.set("domain_id_03", domainRequestData.data.data.data.domain_id);
      Cookies.set("domain_name_03", domainRequestData.data.data.data.domain_name);
      return "Normal";
    } else {

      return "NotFound";
    }
  }
};
