import React, { useState, useContext } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { changePassword, initForm } from "./change_password_logic";
import OneColumn from "../layout/containers/OneColumn";
import DomainContext from "../domains/domainPathContext";
import Cookies from 'js-cookie';
import LoaderContext from "../layout/shared/loader_context";
import Loader from "../layout/shared/Loader";

const ChangePassword = props => {
  const [form, setForm] = useState(initForm);
  const subdomain = useContext(DomainContext);
  const layout = JSON.parse(Cookies.get('layout_03'));
  const loader = useContext(LoaderContext);

  const onChangeHandler = event => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  let pageConfig = {
    active: "change_password",
    title: "Change password"
  };

  const onSubmitFormHandler = async e => {
    e.preventDefault();
    loader.show(true);
    await changePassword(form, props, subdomain);
    loader.show(false);
  };

  return (
    <OneColumn
      {...props}
      pageConfig={pageConfig}
      hideTopMenu={true}
      fluid
      noTitle
    >
      <Container
        fluid={true}
        className="min-height-100vh bg-size-cover"
        style={{
          backgroundImage: `url(${layout.app_background_session})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'
        }}
      >
        <Container>
          <Row style={{ minHeight: "100vh" }}>
            <Col
              sm={12}
              md={{ span: 10, offset: 1 }}
              lg={{ span: 8, offset: 2 }}
              xl={{ span: 6, offset: 3 }}
              className="mY-30"
            >
              <Card className="mY-40@sm+">
                <Card.Header>
                  <Container>
                    <Row>
                      <Col
                        xs={12}
                        md={{ span: 10, offset: 1 }}
                        className="pT-20 pB-10 pT-30@sm+ pB-20@sm+"
                      >
                        <h4 className="mb-0 subtitle text-center">
                          Cambio de contraseña
                        </h4>
                      </Col>
                    </Row>
                  </Container>
                </Card.Header>
                <Card.Body>
                  <Form onSubmit={onSubmitFormHandler}>
                    <Form.Group controlId="formBasicNewPassword">
                      <Form.Label className="font-weight-bold text-left w-100">
                        Nueva contraseña:
                      </Form.Label>
                      <Form.Control
                        type="password"
                        name="newPassword"
                        placeholder="Ingresa aquí la nueva contraseña"
                        required
                        minLength="8"
                        autoFocus
                        onChange={onChangeHandler}
                        value={form.newPassword}
                      />
                    </Form.Group>

                    <Form.Group controlId="formBasicPasswordConfirm">
                      <Form.Label className="font-weight-bold text-left w-100">
                        Confirmación de contraseña:
                      </Form.Label>
                      <Form.Control
                        type="password"
                        name="passwordConfirm"
                        placeholder="Confirma la contraseña anterior"
                        minLength="8"
                        required
                        onChange={onChangeHandler}
                        value={form.passwordConfirm}
                      />
                    </Form.Group>

                    <Form.Group controlId="formBasicOldPassword">
                      <Form.Label className="font-weight-bold text-left w-100">
                        Contraseña actual:
                      </Form.Label>
                      <Form.Control
                        type="password"
                        name="oldPassword"
                        placeholder="Proporciona tu contraseña actual"
                        minLength="8"
                        required
                        onChange={onChangeHandler}
                        value={form.oldPassword}
                      />
                    </Form.Group>

                    <Row className="btn-group-sign-up pY-20">
                      <Col xs={12} sm={12} className="dinamic-form-tool-bar">
                        <Button
                          variant="secondary"
                          className="float-left"
                          onClick={() =>
                            props.history.push(`/${subdomain}/home`)
                          }
                        >
                          Cancelar
                        </Button>
                        <Button
                          variant="primary"
                          type="submit"
                          className="float-right"
                        >
                          Actualizar
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>
      <Loader/>
    </OneColumn>
  );
};

export default ChangePassword;
