import React from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Request } from "../classes/Request";
import Cookies from "js-cookie";

/**********************************************************FORGOT PASSWORD************************************************************************/
export const getListOfTask = async (props, setTaskDetails, subdomain) => {
  const { userId_03 } = Cookies.get();

  const request = new Request(
    `${process.env.REACT_APP_URL_API_LARAVEL}/executed_stages/${props.el.id}/${userId_03}?token=${process.env.REACT_APP_TOKEN}`,
    "get",
    null,
    {}
  );

  const getListOfTaskData = await request.executeRequest();
  if (getListOfTaskData.code === 200) {
    //MOSTRAR ALERTA EXITOSA CON EL MENSAJE QUE RETORNA LA API COMPONENTE
    const taskList = getListOfTaskData.data.data.data.stages.map(
      (el, index) => {
        return (
          <Dropdown.Item
            key={index}
            as={Link}
            to={`/${subdomain}/stage_detail?task=${el.id}`}
          >
            {el.name}
          </Dropdown.Item>
        );
      }
    );
    setTaskDetails(taskList);
  } else {
    //MOSTRAR ALERTA ERRONEA CON EL MENSAJE QUE RETORNA LA API COMPONENTE

    setTaskDetails(
      <Dropdown.Item href="#/action-1">
        No se encontraron pasos para esta etapa
      </Dropdown.Item>
    );
  }
};
