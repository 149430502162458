import { NotificationManager } from "react-notifications";
import Cookies from 'js-cookie';
import { RequestNewVersion } from "../classes/RequestNewVersion";

export const initForm = {
  email: ""
};

/**********************************************************FORGOT PASSWORD************************************************************************/
export const forgotPassword = async (form, props, subdomain) => {
  const request = new RequestNewVersion(
    `${process.env.REACT_APP_URL_API_LARAVEL}/auth/password`,
    "post",
    null,
    {
      email: form.email,
      app_id: 3,
      domain_id: parseInt(Cookies.get('domain_id_03')),
      domain_name: Cookies.get('domain_name_03')
    }
  );
  const forgotPasswordData = await request.executeRequest();

  if ( forgotPasswordData.code !== 200 ) {

    NotificationManager.error("Ha sucedido algo y no se ha podido efectuar la solicitud, intentálo nuevamente");
  }
  else {

    if (
      forgotPasswordData.response.error && 
      forgotPasswordData.response.message && 
      forgotPasswordData.response.message !== "" && 
      typeof forgotPasswordData.response.message === "string" 
    ) {

      NotificationManager.error( forgotPasswordData.response.message );
    }
    else {

      setTimeout(() => {
        NotificationManager.success( forgotPasswordData.response.message );
      }, 800);
      props.history.push(`/${subdomain}/`);
    }
  }
};
/***********************************************************************************************************************************************/
